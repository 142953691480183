import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import ApplicationContext from "../../services/applicationContext";
import Auth from "../../services/authService";

class ForgotPasswordFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			username: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.passwordForgottenFormSchema;

	// @Override
	doSubmit = async () => {
		try {
			await Auth.forgotPassword(this.state.data);
			this.props.history.push("/vergessen/danke");
		} catch (ex) {
			Auth.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<Input
						label="E-Mail"
						name="username"
						error={errors.username}
						type="text"
						placeholder=""
						value={data.username}
						onChange={this.handleChange}
					/>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<button type="submit" className="btn btn-primary" disabled={this.validate()}>
						Absenden
					</button>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</form>
			</div>
		);
	}
}

const ForgotPasswordForm = withRouter(ForgotPasswordFormComponent);

export default ForgotPasswordForm;
