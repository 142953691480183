import React, { Component } from "react";
import AccountService from "../../services/accountService";
import Format from "../../services/formattingService";

class QrCode extends Component {
	state = {
		data: {},
	};

	async componentDidMount() {
		const { transactionGroupId, accountId } = this.props;
		const data = await AccountService.getQrCode(accountId, transactionGroupId);
		this.setState({ data });
	}

	render() {
		const { data } = this.state;
		return (
			<React.Fragment>
				<div className="text-center pb-1">
					<img src={data.url || "/images/logo192.png"} alt="QR-Code" width="250px" />
				</div>
				<hr />
				<div className="small text-muted">
					<b>Verwendungszweck:</b> {data.purpose || "-"} <br />
					<b>Betrag:</b> {data.value ? Format.money(data.value, data.currency) : "-"} <br />
					<b>Empfänger:</b> {data.payee || "-"} <br />
					<b>IBAN:</b> {data.iban ? Format.iban(data.iban) : "-"} <br />
				</div>
			</React.Fragment>
		);
	}
}

export default QrCode;
