import React from "react";

const AddButton = (props) => {
	const { onClick } = props;

	return (
		<button className="btn btn-lg btn-primary rounded-circle shadow" onClick={onClick}>
			<div title="Neuer Sparstrumpf" style={{ width: "28px", height: "40px", marginTop: "3px" }} className="fs-4">
				+
			</div>
		</button>
	);
};

export default AddButton;
