import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../common/Banner";
import ApplicationContext from "../../services/applicationContext";

class TabbedForms extends Component {
	static contextType = ApplicationContext;

	handleTabClick = (index) => {
		this.setState({ current: index });
	};

	render() {
		const { forms, current, checkLicense } = this.state;
		const Form = forms[current].form;

		return (
			<React.Fragment>
				{checkLicense && !this.context.getLicenseType() && (
					<Banner>
						Bitte verlängere <Link to="/abo">Dein Abo</Link> um diese Funktion zu nutzen.
					</Banner>
				)}
				<div>
					<ul className="nav nav-tabs">
						{forms.map((form, idx) => (
							<li key={idx} className="nav-item">
								<button
									className={"nav-link" + (idx === current ? " active" : "")}
									aria-current="page"
									href="#"
									onClick={() => this.handleTabClick(idx)}
								>
									{form.title}
								</button>
							</li>
						))}
					</ul>
				</div>
				<div className="m-2">
					<Form {...this.props} />
				</div>
			</React.Fragment>
		);
	}
}

export default TabbedForms;
