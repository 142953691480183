import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const FormModal = (props) => {
	const {
		className,
		isOpen,
		title,
		size,
		bodyText,
		bodyComponent: BodyComponent,
		footerComponent: FooterComponent,
		properties,
		verticallyCentered,
		onClose,
		onSubmitted,
	} = props;

	return (
		<React.Fragment>
			<Modal isOpen={isOpen} toggle={onClose} onExit={onClose} backdrop="static" size={size} centered={verticallyCentered}>
				<ModalHeader toggle={onClose}>{title} </ModalHeader>
				<ModalBody>
					<div className={className || "m-2"}>
						{BodyComponent && <BodyComponent onSubmitted={onSubmitted} {...properties} />}
						{bodyText}
					</div>
				</ModalBody>
				{FooterComponent && (
					<ModalFooter>
						<FooterComponent onSubmitted={onSubmitted} {...properties} />
					</ModalFooter>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default FormModal;
