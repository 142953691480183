import React from "react";
import { Link, withRouter } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import ApplicationContext from "../../services/applicationContext";
import Auth from "../../services/authService";
import errorMessages from "../../conf/errors.json";

class ResetPasswordFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			username: "",
			token: "",
			password: "",
			passwordConfirmation: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.resetPasswordFormSchema;
	getPropertySchema = () => Validation.schemas.resetPasswordFormPropertySchema;

	// @Override
	doSubmit = async () => {
		const { token, password } = this.state.data;
		try {
			await Auth.resetPassword({ token, password });
			this.props.history.push("/vergessen/erfolgreich");
		} catch (ex) {
			Auth.handleExpectedErrors(ex, this);
		}
	};

	fireErrorToast = (message) => {
		toast.error(message, {
			onClose: () => this.props.history.push("/vergessen"),
		});
	};

	componentDidMount() {
		const { token } = this.props.match.params;
		try {
			const tokenContent = jwtDecode(token);
			console.log("token", tokenContent);

			const { exp, email } = tokenContent;
			console.log("exp, date", exp * 1000, new Date().getTime());
			if (exp * 1000 >= new Date().getTime()) {
				const data = { ...this.state.data };
				data.token = token;
				data.username = email;
				this.setState({ data });
			} else {
				this.fireErrorToast(errorMessages["expired_token"]);
			}
		} catch (ex) {
			console.log("Token invalid");
			this.fireErrorToast(errorMessages["invalid_token"]);
		}
	}

	getPasswordNotice = () => {
		const { passwordMax, passwordMin, passwordPattern } = this.context.configuration;
		const text1 = " Zeichen, mindestens je eine Zahl, Klein- und Großbuchstabe";
		const text2 = " Zeichen";
		return (passwordMin || "1") + "-" + (passwordMax || "500") + (passwordPattern ? text1 : text2);
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		return (
			<div>
				{data.token && (
					<form onSubmit={this.handleSubmit}>
						<Input
							label="E-Mail"
							name="username"
							error={errors.username}
							type="text"
							placeholder=""
							value={data.username}
							disabled
							onChange={this.handleChange}
						/>
						<Input
							label="Passwort"
							name="password"
							notice={this.getPasswordNotice()}
							error={errors.password}
							type="password"
							placeholder=""
							value={data.password}
							onChange={this.handleChange}
						/>
						<Input
							label="Passwort bestätigen"
							name="passwordConfirmation"
							error={errors.passwordConfirmation}
							type="password"
							placeholder=""
							value={data.passwordConfirmation}
							onChange={this.handleChange}
						/>
						{errorMessage && (
							<div className="alert alert-danger" role="alert">
								{errorMessage}
							</div>
						)}

						<button type="submit" className="btn btn-primary" disabled={this.validate()}>
							Absenden
						</button>

						<div className="form-text mt-2">
							Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
						</div>
					</form>
				)}
			</div>
		);
	}
}

const ResetPasswordForm = withRouter(ResetPasswordFormComponent);

export default ResetPasswordForm;
