import Highcharts from "highcharts";
import SandSignika from "highcharts/themes/sand-signika";

class HighchartsService {
	getLangOptions = (culture) => {
		const formatDate = (date, options) => new Intl.DateTimeFormat(culture, options).format(date);
		const formattedNumber = new Intl.NumberFormat(culture).formatToParts(99999.99);

		// to get the decimal and thousands separator, we must format a real number
		const { value: decimalPoint } = formattedNumber.find((part) => part.type === "decimal");
		const { value: thousandsSep } = formattedNumber.find((part) => part.type === "group");

		// to get the days of the week and months we must use real dates and parse them
		// the dates used don't matter
		const daysOfWeek = new Array(7)
			.fill(0)
			.map((_, i) => {
				const date = new Date(2021, 1, i + 1);
				return {
					day: date.getDay(),
					weekday: formatDate(date, { weekday: "long" }),
					shortWeekday: formatDate(date, { weekday: "short" }),
				};
			})
			.sort((a, b) => a.day - b.day);

		const months = new Array(12)
			.fill(0)
			.map((_, i) => {
				const date = new Date(2021, i + 1);
				return {
					month: date.getMonth(),
					shortMonth: formatDate(date, { month: "short" }),
					longMonth: formatDate(date, { month: "long" }),
				};
			})
			.sort((a, b) => a.month - b.month);

		return {
			decimalPoint: decimalPoint,
			thousandsSep: thousandsSep,
			weekdays: daysOfWeek.map((d) => d.weekday),
			shortWeekdays: daysOfWeek.map((d) => d.shortWeekday),
			months: months.map((d) => d.longMonth),
			shortMonths: months.map((d) => d.shortMonth),
		};
	};

	constructor() {
		Highcharts.setOptions({
			lang: this.getLangOptions("de-DE"),
		});

		SandSignika(Highcharts);
	}

	init = () => {};
}

const highchartsService = new HighchartsService();

export default highchartsService;
