import React, { Component } from "react";
import Format from "../../services/formattingService";
import { InfoCircleFill } from "react-bootstrap-icons";
import TransactionGroupActions from "./transactionGroupActions";
import TransactionActions from "./transactionActions";
import CollapsableEntry from "./forms/collapsableEntry";
import ApplicationContext from "../../services/applicationContext";
import QrCode from "../protected/qrCode";

class TransactionTrableGroupEntry extends Component {
	static contextType = ApplicationContext;
	state = { collapsed: true };

	getTDClasses = (last) => {
		const { collapsed } = this.state;

		if (collapsed) return "";
		else return last ? "" : "pb-0 border-0";
	};

	toggleCollapse = () => {
		this.setState({ collapsed: !this.state.collapsed });
	};

	handleTransactionGroupProcessed = () => {
		const { transactionGroup } = this.props;
		Object.values(transactionGroup).forEach((t) => t.onProcessed(t.id));
	};

	handleQR = () => {
		const { transactionGroup, account } = this.props;
		const anyStockingId = Object.keys(transactionGroup)[0];
		const transactionGroupId = transactionGroup[anyStockingId].groupId;
		const { accountId } = account;

		this.context.showFormModal("SEPA Überweisung", QrCode, { transactionGroupId, accountId }, "md", this.closeQRModal);
	};

	closeQRModal = () => {
		console.log("close modal");
	};

	getDefaultDescription = () => {
		const { transactionGroup } = this.props;
		return Object.values(transactionGroup)[0].description;
	};

	getTotal = () => {
		const { transactionGroup } = this.props;
		let total = 0;
		Object.values(transactionGroup).forEach((transaction) => (total += transaction.value));
		return total;
	};

	getStockingAsMap = () => {
		const { stockings } = this.props;
		const stockingMap = {};
		stockings.forEach((stocking) => (stockingMap[stocking.stockingId] = stocking));
		return stockingMap;
	};

	getTransactionCount = () => {
		const { transactionGroup } = this.props;
		return Object.keys(transactionGroup).length;
	};

	getDisableQr = () => {
		const { factor, account } = this.props;
		return factor === -1 || !account.iban || !account.owner || !this.context.getLicenseType();
	};

	getDisableQrReason = () => {
		const { factor, account } = this.props;
		if (factor === -1) return "Dieses Feature steht derzeit nicht für Auszahlungen zur Verfügung";
		else if (!account.iban || !account.owner) return "Dieses Feature steht nur zur Verfügung, wenn Du IBAN und Kontoinhaber zum Basiskonto hinterlegt hast";
		else if (!this.context.getLicenseType()) return "Dieses Feature steht nur mit gültiger Lizenz zur Verfügung";
		else return null;
	};

	render() {
		const { currency, transactionGroup, factor } = this.props;
		const { collapsed } = this.state;

		const description = this.getDefaultDescription();
		const total = this.getTotal();
		const stockings = this.getStockingAsMap();
		const transactionCount = this.getTransactionCount();

		return (
			<React.Fragment>
				<tr className="d-table-row d-sm-none">
					<td colSpan="3" className="fw-bold border-0">
						<CollapsableEntry value={description} collapsed={collapsed} onToggleCollapse={this.toggleCollapse} />
					</td>
				</tr>
				<tr>
					<td className={this.getTDClasses()}>
						<div className="d-none d-sm-block">
							<CollapsableEntry value={description} collapsed={collapsed} onToggleCollapse={this.toggleCollapse} />
						</div>
					</td>
					<td className={this.getTDClasses()}>
						<div className="text-end">{Format.money(total * (factor || 1), currency)}</div>
					</td>
					<td className={this.getTDClasses()}>
						<TransactionGroupActions
							processed={false}
							onProcessed={this.handleTransactionGroupProcessed}
							onQR={this.context.configuration.qrcodesEnabled !== "no" && this.handleQR}
							disableQR={this.getDisableQr()}
							disableQRReason={this.getDisableQrReason()}
						/>
					</td>
				</tr>
				{!collapsed &&
					Object.keys(transactionGroup)
						.sort((a, b) => stockings[a].position - stockings[b].position)
						.map((stockingId, idx) => (
							<tr key={stockingId} className="small">
								<td className={this.getTDClasses(idx === transactionCount - 1)}>
									<div className="d-flex ms-4 text-nowrap text-truncate">
										<div>{stockings[stockingId].name}</div>
										{transactionGroup[stockingId].description !== description && (
											<div className="ms-1 text-primary" style={{ marginTop: "-2px" }} title={transactionGroup[stockingId].description}>
												<InfoCircleFill size={12} />
											</div>
										)}
									</div>
								</td>
								<td className={this.getTDClasses(idx === transactionCount - 1)}>
									<div className="text-end">{Format.money(transactionGroup[stockingId].value * (factor || 1), currency)}</div>
								</td>
								<td className={this.getTDClasses(idx === transactionCount - 1)}>
									<TransactionActions small onDelete={() => transactionGroup[stockingId].onDelete(transactionGroup[stockingId].id)} />
								</td>
							</tr>
						))}
			</React.Fragment>
		);
	}
}

export default TransactionTrableGroupEntry;
