import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../css/home.css";
import ApplicationContext from "../services/applicationContext";

const Home = () => {
	const context = useContext(ApplicationContext);
	const { registrationFreemonths } = context.configuration;
	return (
		<div className="m-3">
			<center>
				<div className="home-titleContainer">
					<img className="img-fluid pt-3" src="../images/sparstrumpf.png" alt="Logo" />
				</div>
				<div className="home-imageContainer"> {/* Image Container */} </div>
				<div className="row py-4">
					<div className="col-lg-2"></div>
					<div className="col-lg-8 display-6">
						<p>
							Es stehen gro&szlig;e Ausgaben an? Hochzeit, Traumreise oder ein neues Auto? Sparstrumpf hilft Dir auf <b>alle</b> Deine Ziele hin
							zu sparen.
						</p>
					</div>
				</div>

				<div className="row py-4">
					<div className="col-lg-2"></div>
					<div className="col-lg-8 display-6">
						Du bist kein Fan von j&auml;hrlicher Zahlungsweise? Zu viel Geld auf einmal? Sparstrumpf hilft Dir dabei! Und vielleicht kannst Du damit
						sogar Geld sparen.
					</div>
				</div>

				<div className="row py-5">
					<div className="col-lg-2"></div>
					<div className="col-lg-8 display-6">
						Du m&ouml;chtest f&uuml;r alle Eventualit&auml;ten gewappnet sein, und f&uuml;r Deine Immobilie eine Investitionsr&uuml;cklage bilden?
						Sparstrumpf hilft Dir auch dabei!
					</div>
				</div>

				<div className="h1 py-5"> Wie funktioniert's? </div>

				<div className="row">
					<div className="col-lg-1"></div>
					<p className="col-lg-10 justify lead">
						Sparstrumpf ist eine virtuelle Unterkontenverwaltung, die mit diversen M&ouml;glichkeiten zur Planung und Verwaltung von Ein- und
						Auszahlungen aufwartet. Die St&auml;rke von Sparstrumpf liegt dabei in den automatisierten Berechnungen um schonend und
						gleichm&auml;&szlig;ig ein definiertes Sparziel zu erreichen. Ein reales Spar-Konto (zum Beispiel ein separates Tagesgeldkonto) wird
						somit virtuell in viele kleine Unterkonten, den Sparstr&uuml;mpfen, aufgeteilt. Du kannst zu jeder Zeit sehen, in welchem Sparstrumpf
						wie viel Geld steckt, und wann Dein Sparziel erreicht sein wird. Wir ermitteln f&uuml;r Dich Monat f&uuml;r Monat die Gesamteinzahlung,
						die Du dann - unabh&auml;ngig von uns - auf Dein Spar-Konto &uuml;berweist.
					</p>
				</div>
				<div className="row py-5">
					<div className="col-lg-1"></div>
					<p className="col-lg-10 justify lead">
						Du kannst mit einem kurzen Saldencheck pr&uuml;fen, ob Dein Basis-Konto und die Gesamtheit Deiner Sparstr&uuml;mpfe &uuml;berein stimmt.
						Wenn es an der Zeit ist, Geld f&uuml;r eine bestimmte Anschaffung aus einem Sparstrumpf zu holen, sagen wir Dir nat&uuml;rlich auch
						Bescheid.
					</p>
				</div>
				<div className="h1 py-5"> Coming Soon!</div>
				<p className="lead">Einfach anmelden und kostenfrei {registrationFreemonths} Monate ausprobieren!</p>
				<Link className="btn btn-primary btn-lg m-3" to="/registrieren">
					Anmelden
				</Link>
				<div style={{ height: "150px" }}></div>
			</center>
		</div>
	);
};

export default Home;
