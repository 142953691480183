import React, { Component } from "react";
import ListCard from "../common/listCard";
import Format from "../../services/formattingService";

class LicenseCard extends Component {
	render() {
		const { license, expired } = this.props;
		const items = [
			{ label: "Level", value: license ? license.type : "-" },
			{ label: "gültig ab", value: license ? Format.datetime(new Date(license.validFrom)) : "-" },
			{ label: "gültig bis", value: license ? Format.datetime(new Date(license.validTo)) : "-" },
		];
		const link = {
			label: "Erneuern",
			to: "/app/user/abo",
		};

		if (expired) items[0].value = items[0].value + " (Abgelaufen)";

		return <ListCard title="Lizenzdaten" items={items} link={expired ? link : null} />;
	}
}

export default LicenseCard;
