import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsService from "../../services/highchartsService";

const LineChart = (props) => {
	const { chart } = props;
	HighchartsService.init();

	const options = {
		...chart,
		accessibility: {
			enabled: false,
		},
	};

	return (
		<div className="shadow">
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default LineChart;
