import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import ApplicationContext from "../services/applicationContext";
import ForgotPasswordForm from "./forms/forgotPasswordForm";

const ForgotPassword = (props) => {
	const context = useContext(ApplicationContext);
	if (context.user) return <Redirect to="/app/start" />;

	return (
		<React.Fragment>
			<div className="row mx-3">
				<div className="col-md-2 col-lg-3 col-xl-3"></div>
				<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
					<center>
						<h1 className="mt-5">Passwort vergessen</h1>
						<p className="mt-5">
							Noch kein Konto? Einfach kostenfrei <Link to="/registrieren">registrieren</Link>.
						</p>
						<p>Wir senden Dir eine E-Mail mit weiteren Instruktionen um Dein Passwort zurückzusetzen.</p>
					</center>

					<ForgotPasswordForm />
				</div>
				<div className="col-auto"> </div>
			</div>
		</React.Fragment>
	);
};

export default ForgotPassword;
