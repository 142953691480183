import React from "react";
import DateRangeForm from "../../forms/dateRangeForm";
import Format from "../../../services/formattingService";

const DateRangeSelector = (props) => {
	const { onRangeSelect } = props;
	const year = new Date().getFullYear();
	const month = new Date().getMonth();
	const quartal = (month + 1) % 3;

	const toRoman = (i) => {
		if (i <= 3) return "I".repeat(i);
		else if (i === 4) return "IV";
	};

	const buttons = [
		{
			title: Format.month(new Date()),
			from: new Date(year, month, 1),
			to: new Date(new Date(year, month + 1, 1).getTime() - 1),
		},
		{
			title: toRoman(quartal + 1) + ". Quartal",
			from: new Date(year, quartal * 3, 1),
			to: new Date(new Date(year, (quartal + 1) * 3, 1).getTime() - 1),
		},
		{
			title: "-6M",
			from: new Date(year, month - 5, 1),
			to: new Date(new Date(year, month + 1, 1).getTime() - 1),
		},
		{
			title: "-12M",
			from: new Date(year, month - 11, 1),
			to: new Date(new Date(year, month + 1, 1).getTime() - 1),
		},
		{
			title: year,
			from: new Date(year, 0, 1),
			to: new Date(new Date(year + 1, 0, 1).getTime() - 1),
		},
		{
			title: year - 1 + " - " + (year + 1),
			from: new Date(year - 1, 0, 1),
			to: new Date(new Date(year + 2, 0, 1).getTime() - 1),
		},
		{
			title: "Alles",
			from: new Date(2000, 0, 1),
			to: new Date(new Date(2100, 0, 1).getTime() - 1),
		},
	];

	const getIsActive = (index) => {
		// No Dates are Set
		if (props.from === null || props.to === null) return false;

		// Anpassen-Button
		if (index === -1) {
			var anyOther = false;
			for (var i = 0; i < buttons.length; i++) anyOther |= getIsActive(i);
			return !anyOther;
		} else {
			// Alle anderen Buttons
			return props.from.getTime() === buttons[index].from.getTime() && props.to.getTime() === buttons[index].to.getTime();
		}
	};

	return (
		<div className="mt-3 px-3 px-sm-5 px-md-5 px-lg-1 px-xl-4 px-xxl-4 row">
			{buttons.map((button, idx) => (
				<div className="px-1 col-6 col-lg-3 d-grid pb-2" key={idx}>
					<button
						type="button"
						className={"btn btn-primary" + (getIsActive(idx) ? " active" : "")}
						onClick={() => onRangeSelect(button.from, button.to)}
					>
						{button.title}
					</button>
				</div>
			))}

			<div className="px-1 col-6 col-lg-3 d-grid pb-2">
				<div className="btn-group">
					<button
						type="button"
						className={"btn btn-primary dropdown-toggle" + (getIsActive(-1) ? " active" : "")}
						data-bs-toggle="dropdown"
						data-bs-auto-close="outside"
						aria-expanded="false"
					>
						Anpassen...
					</button>

					<div className="dropdown-menu dropdown-menu-end pt-2 pb-2 px-2">
						<DateRangeForm from={props.from} to={props.to} onSubmit={onRangeSelect} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DateRangeSelector;
