import React, { PureComponent } from "react";
import { GripVertical } from "react-bootstrap-icons";
import StockingButtons from "./stockingButtons";
import StockingTitle from "./stockingTitle";
import Format from "../../../services/formattingService";

class StockingHead extends PureComponent {
	handleCreate = (transactionType) => {
		const { stocking, onCreate } = this.props;
		onCreate(stocking.stockingId, transactionType);
	};

	handleEdit = () => {
		const { stocking, onEdit } = this.props;
		onEdit(stocking);
	};

	handleSaldoClick = () => {
		const { value } = this.props;
		navigator.clipboard.writeText(Format.number(value, 2));
	};

	render() {
		const { currency, value } = this.props;
		const { stocking } = this.props;
		const { name, color } = stocking;

		return (
			<div className="stocking mb-4 bg-secondary shadow-sm" style={{ borderLeft: "solid", borderWidth: "10px", borderColor: color || "#23ac4f" }}>
				<div className="mx-2">
					<StockingTitle name={name} />
				</div>

				<div className="mx-2 border-top">
					<StockingButtons stocking={stocking} onCreate={this.handleCreate} onEdit={this.handleEdit} />
				</div>

				<div className="d-flex justify-content-end mt-1 me-1" style={{ height: "30px" }}>
					<div className="me-auto text-light d-none d-lg-block" title="Drag'n'Drop">
						<GripVertical className="mt-1 mt-xl-0" size={20} />
					</div>
					<div
						className="bg-info pe-2 ms-1 ms-lg-0 d-flex justify-content-end align-items-center user-select-all"
						style={{ width: "150px", maxWidth: "100%", cursor: "alias" }}
						title="Aktueller Saldo"
						onClick={this.handleSaldoClick}
					>
						{value && Format.money(value, currency)}
					</div>
				</div>
			</div>
		);
	}
}

export default StockingHead;
