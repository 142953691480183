import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class TableBody extends Component {
	renderCell(item, column) {
		if (column.content) return column.content(item);
		else return _.get(item, column.path);
	}

	getCellClasses = (column) => {
		const align = column.align || "start";
		return "text-" + align;
	};

	render() {
		const { data, columns } = this.props;
		return (
			<tbody>
				{data.map((item, idx) => (
					<tr key={idx}>
						{columns.map((column) => (
							<td className={this.getCellClasses(column)} key={idx + (column.path || column.key)}>
								{this.renderCell(item, column)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		);
	}
}

export default TableBody;

TableBody.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
};
