import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

function init() {
	Sentry.init({
		dsn: "https://4e8ce8f6ba8f4ad28548ecdb56a62100@o568639.ingest.sentry.io/6167986",
		release: process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION,
		environment: process.env.NODE_ENV,
		// integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		// tracesSampleRate: 1.0,
	});
}

function logException(error) {
	Sentry.captureException(error);
}

function logMessage(message) {
	Sentry.captureMessage(message);
}

const exports = {
	init,
	logException,
	logMessage,
};

export default exports;
