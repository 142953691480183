import React, { Component } from "react";
import Format from "../../services/formattingService";
import BadgeWithPopover from "./BadgeWithPopover";

class ListCardEntry extends Component {
	badgeRef = React.createRef();
	errorBadgeRef = React.createRef();

	toggleBadgePopover = () => {
		this.setState({ errorBadgePopoverOpen: false, badgePopoverOpen: !this.state.badgePopoverOpen });
	};

	toggleErrorBadgePopover = () => {
		this.setState({ badgePopoverOpen: false, errorBadgePopoverOpen: !this.state.errorBadgePopoverOpen });
	};

	state = {
		badgePopoverOpen: false,
		errorBadgePopoverOpen: false,
	};

	render() {
		const { label, value, badge, errorBadge, badgePopoverTitle, errorBadgePopoverTitle, badgePopoverBody, errorBadgePopoverBody, currency } = this.props;

		return (
			<li className="list-group-item">
				<div className="text-muted small"> {label} </div>
				<div className="text-start">
					<span className="user-select-all me-2">{value !== null && currency ? Format.money(value, currency) : value}</span>
					{errorBadge > 0 && (
						<React.Fragment>
							<BadgeWithPopover
								className="me-1 bg-danger d-none d-sm-inline-block"
								value={errorBadge}
								popoverPlacement="left"
								popoverTitle={errorBadgePopoverTitle}
								popoverRender={errorBadgePopoverBody}
							/>
							<BadgeWithPopover
								className="me-1 bg-danger d-inline-block d-sm-none"
								value={errorBadge}
								popoverPlacement="bottom"
								popoverTitle={errorBadgePopoverTitle}
								popoverRender={errorBadgePopoverBody}
							/>
						</React.Fragment>
					)}

					{badge > 0 && (
						<React.Fragment>
							<BadgeWithPopover
								className="bg-primary d-none d-sm-inline-block"
								value={badge}
								popoverPlacement="right"
								popoverTitle={badgePopoverTitle}
								popoverRender={badgePopoverBody}
							/>
							<BadgeWithPopover
								className="bg-primary d-inline-block d-sm-none"
								value={badge}
								popoverPlacement="bottom"
								popoverTitle={badgePopoverTitle}
								popoverRender={badgePopoverBody}
							/>
						</React.Fragment>
					)}
				</div>
			</li>
		);
	}
}

export default ListCardEntry;
