import React from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { XLg } from "react-bootstrap-icons";

const TransactionPopover = (props) => {
	const { isOpen, target, onClose, title, render, placement, color, isWithinModal } = props;

	return (
		<Popover trigger="manual" placement={placement || "top"} isOpen={isOpen} target={target} className={isWithinModal ? "popoverWithinModal" : ""}>
			<PopoverHeader className={"d-flex align-items-center" + (color ? " bg-" + color : "")}>
				<span className="me-auto">{title}</span>
				<XLg className="ms-3 text-secondary" size={16} style={{ cursor: "pointer" }} onClick={onClose} />
			</PopoverHeader>
			<PopoverBody className="">{render()}</PopoverBody>
		</Popover>
	);
};

export default TransactionPopover;
