import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import ApplicationContext from "../../services/applicationContext";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
	const context = useContext(ApplicationContext);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (context.user === undefined) return null;
				else if (context.user !== null) return Component ? <Component {...props} /> : render(props);
				else return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
			}}
		/>
	);
};

export default ProtectedRoute;
