import http from "./httpService";
import Format from "./formattingService";

async function getReport(accountId, reportName, from, to) {
	const { data } = await http.get("accounts/" + accountId + "/reports/" + reportName + "/" + Format.isodate(from) + "/" + Format.isodate(to));
	return data;
}

const reportService = {
	getReport,
};

export default reportService;
