import React from "react";

const StockingTitle = (props) => {
	const { name } = props;

	return (
		<React.Fragment>
			<div className="mx-2 d-none d-xl-block lead text-light text-nowrap text-truncate user-select-all" title={name}>
				{name}
			</div>
			<div className="mx-2 d-block d-xl-none pt-2 pt-lg-1 mb-1 text-light text-nowrap text-truncate user-select-all" title={name}>
				{name}
			</div>
		</React.Fragment>
	);
};

export default StockingTitle;
