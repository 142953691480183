import { electronicFormatIBAN, isValidIBAN, isValidBIC } from "ibantools";

const extension = (joi) => {
	return {
		type: "string",
		base: joi.string(),
		rules: {
			iban: {
				validate(value, helpers) {
					const newVal = electronicFormatIBAN(value);

					if (isValidIBAN(newVal)) return newVal;
					return helpers.error("string.invalidIBAN", { value });
				},
			},
			swiftbic: {
				validate(params, value, state, options) {
					if (isValidBIC(value)) return value;
					return this.createError("string.invalidSWIFTBIC", { value }, state, options);
				},
			},
		},
	};
};

export default extension;
