import React from "react";

const Select = (props) => {
	const { name, label, notice, error, value, onChange, options } = props;

	return (
		<div className="mb-3">
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<select name={name} id={name} value={value} className="form-select" onChange={onChange}>
				{options.map((o) => (
					<option key={o.value} value={o.value}>
						{o.label}
					</option>
				))}
			</select>
			{error && <div className="form-text text-danger ms-1">{error}</div>}
			{!error && <div className="form-text ms-1">{notice}</div>}
		</div>
	);
};

export default Select;
