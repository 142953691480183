import React, { Component } from "react";
import { PlusCircle, BoxArrowInRight, BoxArrowRight } from "react-bootstrap-icons";
import Format from "../../../services/formattingService";

class PlateCenterTwoLine extends Component {
	buttonStyle = { cursor: "pointer" };

	getSpanClasses = () => {
		const { isFuture } = this.props;
		return isFuture ? "text-muted" : "text-dark";
	};

	render() {
		const { currency, deposits, payouts, onCreate } = this.props;
		return (
			<React.Fragment>
				<div className="d-flex align-items-center mb-1 px-1" style={{ height: "22px" }}>
					<BoxArrowInRight className="me-auto text-secondary" />
					<span className={this.getSpanClasses()}>{deposits !== 0 && Format.money(deposits, currency)}</span>
					<div title="Neue Einzahlung">
						<PlusCircle className="ms-1 text-primary" style={this.buttonStyle} onClick={() => onCreate("deposit")} />
					</div>
				</div>
				<div className="d-flex justify-content-start align-items-center ps-2 pe-1" style={{ height: "22px" }}>
					<BoxArrowRight className="me-auto text-secondary" />
					<span className={this.getSpanClasses()}>{payouts !== 0 && Format.money(payouts, currency)}</span>
					<div title="Neue Auszahlung">
						<PlusCircle className="ms-1 text-primary" style={this.buttonStyle} onClick={() => onCreate("payout")} />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PlateCenterTwoLine;
