import React from "react";

const DialogButtons = (props) => {
	const { buttons, onSubmitted } = props;
	return (
		<div className="d-flex justify-content-end">
			{buttons.map((button, idx) => (
				<button
					key={button.value}
					type="submit"
					value={button.value}
					className={"me-4 btn btn-" + (button.color ? button.color : "primary")}
					onClick={() => onSubmitted(button.value)}
				>
					{button.label}
				</button>
			))}
		</div>
	);
};

export default DialogButtons;
