import RecurringForm from "./recurringForm";
import TabbedForms from "./tabbedForms";
import TransactionForm from "./transactionForm";

class TabbedCreateForm extends TabbedForms {
	state = {
		forms: [
			{ form: TransactionForm, title: "Einmalig" },
			{ form: RecurringForm, title: "Wiederkehrend" },
		],
		current: this.props.current || 0,
		checkLicense: true,
	};
}

export default TabbedCreateForm;
