import React from "react";

const CheckBox = (props) => {
	const { error, label, value, name, ...rest } = props;
	return (
		<div className="form-check mb-3">
			<input className="form-check-input" name={name} type="checkbox" id={name} checked={value} {...rest} />
			<label className="form-check-label" htmlFor={name}>
				{label}
			</label>
			{error && <div className="form-text text-danger ms-1">{error}</div>}
		</div>
	);
};

export default CheckBox;
