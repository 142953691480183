import React from "react";
import "font-awesome/css/font-awesome.css";
import PropTypes from "prop-types";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableFooter from "./tableFooter";

const Table = (props) => {
	const { columns, data, footer, sort, onSort } = props;

	return (
		<table className="table table-striped table-hover shadow">
			<TableHeader columns={columns} sort={sort} onSort={onSort} />
			<TableBody data={data} columns={columns} />
			{footer && <TableFooter data={footer} columns={columns} />}
		</table>
	);
};

export default Table;

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	sort: PropTypes.object.isRequired,
	onSort: PropTypes.func.isRequired,
};
