import http from "./httpService";
import Format from "./formattingService";

async function getTransactions(accountId, stockingId, from, to) {
	const { data } = await http.get("accounts/" + accountId + "/stockings/" + stockingId + "/transactions?from=" + from + "&to=" + to);
	const transactions = data.transactions.map((transaction) => ({ ...transaction, bookingDate: new Date(transaction.bookingDate + "T00:00:00") }));
	data.transactions = transactions;
	return data;
}

async function updateTransaction(accountId, stockingId, transactionId, changes) {
	const { data: transaction } = await http.patch("accounts/" + accountId + "/stockings/" + stockingId + "/transactions/" + transactionId, changes);
	transaction.bookingDate = new Date(transaction.bookingDate + "T00:00:00");
	return transaction;
}

async function deleteTransaction(accountId, stockingId, transactionId) {
	return http.delete("accounts/" + accountId + "/stockings/" + stockingId + "/transactions/" + transactionId);
}

async function getTransactionsOfMonth(accountId, month) {
	const { data } = await http.get("accounts/" + accountId + "/transactions/" + Format.isodate(month));
	const transactions = data.map((transaction) => ({ ...transaction, bookingDate: new Date(transaction.bookingDate + "T00:00:00") }));
	return transactions;
}

async function createTransaction(accountId, stockingId, transactionRequest) {
	const { data } = await http.post("accounts/" + accountId + "/stockings/" + stockingId + "/transactions", transactionRequest);
	const transaction = { ...data, bookingDate: new Date(data.bookingDate + "T00:00:00") };
	return transaction;
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const transactionService = {
	getTransactions,
	getTransactionsOfMonth,
	updateTransaction,
	deleteTransaction,
	createTransaction,
	handleExpectedErrors,
};

export default transactionService;
