import React, { Component } from "react";
import ListCard from "../common/listCard";
import Format from "../../services/formattingService";

class AccountCard extends Component {
	render() {
		const { account, accountValue } = this.props;
		const items = [
			{ label: "Name", value: account ? account.name : null },
			{ label: "IBAN", value: account && account.iban ? Format.iban(account.iban) : "-" },
			{ label: "Saldo", value: accountValue === undefined ? null : accountValue, currency: account && account.currency },
		];
		const link = {
			label: "Anlegen",
			onClick: this.props.handleAccountCreate,
		};

		return <ListCard title="Basiskonto" items={items} link={account ? null : link} />;
	}
}

export default AccountCard;
