import React from "react";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import Format from "../../services/formattingService";
import Form from "../common/forms/form";
import errorMessages from "../../conf/errors.json";

class DateRangeForm extends Form {
	state = {
		data: {
			from: this.props.from ? this.props.from : new Date(),
			to: this.props.to ? this.props.to : new Date(),
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.dateRangeFormSchema;

	// @Override
	doSubmit = async () => {
		const { from, to } = this.state.data;
		const { onSubmit } = this.props;
		from.setHours(0, 0, 0);
		to.setHours(23, 59, 59);
		onSubmit(from, to);
	};

	// @Override
	render() {
		const { errors, data } = this.state;
		const errorMessage = data.from > data.to ? errorMessages["end_is_before_start"] : null;

		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<div className="d-flex">
						<div className="mx-2">
							<Input label="Start" name="from" error={errors.from} type="date" value={Format.isodate(data.from)} onChange={this.handleChange} />
						</div>

						<div className="mx-2">
							<Input label="Ende" name="to" error={errors.to} type="date" value={Format.isodate(data.to)} onChange={this.handleChange} />
						</div>
					</div>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}
					<center>
						<button type="submit" className="btn btn-primary" disabled={this.validate() || data.from > data.to}>
							Übernehmen
						</button>
					</center>
				</form>
			</div>
		);
	}
}

export default DateRangeForm;
