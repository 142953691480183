import React, { Component } from "react";
import CardHeader from "./cardHeader";
import Stockings from "./stockings";
import "../../css/Main.css";
import Configurator from "./configurator";
import StockingService from "../../services/stockingService";
import OpenBalanceForm from "../forms/openBalanceForm";
import ApplicationContext from "../../services/applicationContext";
import { toast } from "react-toastify";

class Main extends Component {
	static contextType = ApplicationContext;

	handleDisplayChange = (e) => {
		this.setState({ display: e.target.id });
	};

	handleAccountValueUpdate = (value) => {
		this.setState({ accountValue: value });
	};

	handleUnprocessedTransactionsUpdate = (unprocessedTransactions) => {
		this.setState({ unprocessedTransactions });
	};

	handleAccountInitialized = async (account) => {
		const { onAccountInitialized } = this.props;
		this.context.hideModal();
		onAccountInitialized(account);
	};

	showOpenBalanceForm = (account) => {
		this.context.showFormModal("Guthaben verteilen", OpenBalanceForm, { account }, "lg", this.handleAccountInitialized);
	};

	handleDrop = async (startIndex, endIndex) => {
		const { account } = this.props;

		if (startIndex !== endIndex) {
			const accountId = account.accountId;
			const previousStockings = this.state.stockings.map((s) => ({ ...s }));

			const stockings = [...this.state.stockings].filter((stocking) => stocking.visible === true).sort((a, b) => a.position - b.position);
			const position = stockings[endIndex].position;
			const [removed] = stockings.splice(startIndex, 1);
			stockings.splice(endIndex, 0, removed);
			stockings.forEach((s, i) => {
				s.position = i;
			});
			this.setState({ stockings });

			try {
				const changes = { position };
				await StockingService.updateStocking(accountId, removed.stockingId, changes);
			} catch (error) {
				console.log("error", error);
				toast.warn("Deine Änderung konnte nicht übernommen werden.");
				this.setState({ stockings: previousStockings });
			}
		}
	};

	handleStockingUpdate = (stocking) => {
		this.context.hideModal();
		if (stocking !== null) {
			const stockings = [...this.state.stockings];
			const idx = stockings.findIndex((s) => s.stockingId === stocking.stockingId);
			stockings[idx] = stocking;
			this.setState({ stockings });
		}
	};

	handleStockingCreate = (stocking) => {
		this.context.hideModal();
		if (stocking !== null) {
			const stockings = [...this.state.stockings, stocking];
			this.setState({ stockings });
		}
	};

	state = {
		display: "both",
		stockings: [],
	};

	checkAccount = () => {
		const { account } = this.props;
		if (account) {
			if (account.openBalance !== 0) {
				this.showOpenBalanceForm(account);
			} else {
				setTimeout(async () => {
					const stockings = await StockingService.getStockings(account.accountId);
					this.setState({ stockings });
				}, 100);
			}
		}
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.account !== this.props.account && JSON.stringify(prevProps.account) !== JSON.stringify(this.props.account)) {
			this.checkAccount();
		}
	}

	async componentDidMount() {
		this.checkAccount();
	}

	render() {
		const { stockings, display, accountValue, unprocessedTransactions } = this.state;
		const { account, handleAccountCreate } = this.props;
		const stockingsToDisplay = stockings.filter((stocking) => stocking.visible === true).sort((a, b) => a.position - b.position);

		return (
			<div className="m-3">
				<CardHeader
					account={account}
					accountValue={accountValue}
					unprocessedTransactions={unprocessedTransactions}
					stockings={stockingsToDisplay}
					handleAccountCreate={handleAccountCreate}
				/>
				<Configurator display={display} onChange={this.handleDisplayChange} />
				{stockings.length > 0 && (
					<Stockings
						account={account}
						stockings={stockingsToDisplay}
						currency={account && account.currency}
						display={display}
						onAccountValueUpdate={this.handleAccountValueUpdate}
						onUnprocessedTransactionsUpdate={this.handleUnprocessedTransactionsUpdate}
						onDrop={this.handleDrop}
						onUpdate={this.handleStockingUpdate}
						onCreate={this.handleStockingCreate}
					/>
				)}
			</div>
		);
	}
}

export default Main;
