function money(value, currency) {
	const language = window.navigator.userLanguage || window.navigator.language;
	const numberFormat = new Intl.NumberFormat(language, currency ? { style: "currency", currency } : {});
	return numberFormat.format(value);
}

function number(value, digits) {
	const language = window.navigator.userLanguage || window.navigator.language;
	const numberFormat = new Intl.NumberFormat(language, { style: "decimal", minimumFractionDigits: digits });
	return numberFormat.format(value);
}

function datetime(date) {
	const language = window.navigator.userLanguage || window.navigator.language;
	return date.toLocaleDateString(language, { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" });
}

function date(date) {
	const language = window.navigator.userLanguage || window.navigator.language;
	return date.toLocaleDateString(language, { year: "numeric", month: "2-digit", day: "2-digit" });
}

function month(date) {
	const language = window.navigator.userLanguage || window.navigator.language;
	return date.toLocaleDateString(language, { month: "long", year: "2-digit" });
}

function isodate(date) {
	if (date !== null) return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
	else return "";
}

function iban(plainIBAN) {
	var i = 0;
	var formattedIban = "";
	while (i < plainIBAN.length) {
		formattedIban = formattedIban + plainIBAN.substr(i, 4) + " ";
		i += 4;
	}
	formattedIban = formattedIban.trim();
	return formattedIban;
}

const formattingService = {
	money,
	number,
	datetime,
	date,
	month,
	isodate,
	iban,
};

export default formattingService;
