import React, { Component } from "react";
import _ from "lodash";
import reportService from "../../../services/reportsService";
import Table from "../../common/table";
import LineChart from "../../common/lineChart";
import Select from "../../common/forms/select";

class BaseReport extends Component {
	state = {
		data: null,
		sort: null,
		rightLineChart: null,
		topLineChart: null,
		dropDownFilter: null,
	};

	loadData = async () => {
		const { account, from, to, reportName } = this.props;
		if (account) {
			const result = await reportService.getReport(account.accountId, reportName, from, to);
			this.handleData(result.data);
		}
	};

	// Overwrite in child classes
	handleData = (data) => {};

	// Overwrite in child classes
	handleDropDownChange = (e) => {};

	handleSort = (sort) => {
		this.setState({ sort });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
			this.loadData();
		}
	}

	componentDidMount() {
		this.loadData();
	}

	getTableRowClasses = () => {
		return this.state.rightLineChart != null ? "pb-5 gx-4 gx-xxl-5 col-12 col-lg-6" : "col-12";
	};

	render() {
		const { data, columns, footer, sort, topLineChart, rightLineChart, dropDownFilter, dropDownFilterValue } = this.state;
		const sortedData = sort !== null ? _.orderBy(data, [sort.key], [sort.order]) : data;

		return (
			<div className="mb-5">
				<div className="row">
					<div className="col-12 col-sm-6 col-lg-4 col-xl-3">
						{dropDownFilter && (
							<form onSubmit={this.handleSubmit}>
								<Select
									label="Filter"
									placeholder="Alle"
									name="stocking"
									type="text"
									value={dropDownFilterValue}
									options={dropDownFilter}
									onChange={this.handleDropDownChange}
								/>
							</form>
						)}
					</div>
				</div>
				{topLineChart && (
					<div className="row mb-3">
						<div className="col-12">
							<LineChart chart={topLineChart} />
						</div>
					</div>
				)}
				<div className="row" style={{ overflowX: "scroll" }}>
					<div className={this.getTableRowClasses()}>
						{data && <Table data={sortedData} columns={columns} footer={footer} sort={sort} onSort={this.handleSort} />}
					</div>
					{rightLineChart && (
						<div className={this.getTableRowClasses()}>
							<LineChart chart={rightLineChart} />
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default BaseReport;
