import React from "react";

const DateHeaderCenter = (props) => {
	const { months } = props;
	return (
		<div className="stockingHeader d-flex">
			{months.map((month) => (
				<div key={month} className="stockingPlate text-center">
					<span className="badge bg-warning">{month.toLocaleDateString("de-DE", { month: "short", year: "numeric" })}</span>
				</div>
			))}
		</div>
	);
};

export default DateHeaderCenter;
