import React, { Component } from "react";
import ListCard from "../common/listCard";
import Format from "../../services/formattingService";

class UserCard extends Component {
	render() {
		const { user, handle } = this.props;
		const items = [
			{ label: "Name", value: user ? user.name : null },
			{ label: "E-Mail", value: user ? user.email : null },
			{ label: "registriert seit", value: user ? Format.datetime(new Date(user.enrollmentDate)) : null },
		];
		const link = {
			label: "Bearbeiten",
			onClick: () => handle(), // TODO make editing possible
		};

		return <ListCard title="Benutzerdaten" items={items} link={link} />;
	}
}

export default UserCard;
