import React, { Component } from "react";
import { Square, CheckSquare, QrCode } from "react-bootstrap-icons";

class TransactionGroupActions extends Component {
	getButtonStyles = (disabled) => {
		return !disabled ? { cursor: "pointer" } : null;
	};

	getButtonClasses = (marginEnd, textColor, disabled) => {
		return "me-" + marginEnd + " " + (disabled ? "text-secondary opacity-50" : textColor);
	};

	render() {
		const { processed, onProcessed, onQR, disableQR, disableQRReason } = this.props;
		return (
			<div className="d-flex align-items-baseline ms-3 text-nowrap text-center" style={{ marginBottom: "2px" }}>
				{onQR && (
					<div title={disableQRReason || "QR-Code für die Überweisung erzeugen"}>
						<QrCode
							className={this.getButtonClasses(2, "text-primary", disableQR)}
							size={16}
							style={this.getButtonStyles(disableQR)}
							onClick={disableQR ? null : onQR}
						/>
					</div>
				)}

				{onProcessed && (
					<div title="Zahlungsstatus">
						{processed && <CheckSquare className="me-2 text-primary" size={16} style={this.getButtonStyles(false)} />}
						{!processed && <Square className="me-2 text-primary" size={16} style={this.getButtonStyles(false)} onClick={onProcessed} />}
					</div>
				)}
			</div>
		);
	}
}

export default TransactionGroupActions;
