import React, { Component } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { List } from "react-bootstrap-icons";
import StockingButtonGroup from "./stockingButtonGroups";

class StockingButtons extends Component {
	functionButton = React.createRef();

	state = {
		functionPopoverOpen: false,
		functionPopoverStaysOpen: false,
	};

	// PopOver Helper Methods

	handleMouseOverPopover = () => {
		this.setState({ functionPopoverStaysOpen: true });
	};

	handleMouseLeavePopover = () => {
		this.setState({ functionPopoverStaysOpen: false, functionPopoverOpen: false });
	};

	hidePopover = () => {
		if (!this.state.functionPopoverStaysOpen) this.setState({ functionPopoverOpen: false });
	};

	forceHidePopover = () => {
		this.setState({ functionPopoverOpen: false });
	};

	showPopover = () => {
		this.setState({ functionPopoverOpen: true });
	};

	togglePopover = () => {
		const { functionPopoverOpen, functionPopoverStaysOpen } = this.state;
		if (!functionPopoverOpen || !functionPopoverStaysOpen) this.setState({ functionPopoverOpen: !functionPopoverOpen });
	};

	// Button Functions

	handleSettingsClick = () => {
		const { onEdit } = this.props;
		this.forceHidePopover();
		onEdit();
	};

	handleCreateDepositClick = () => {
		const { onCreate } = this.props;
		this.forceHidePopover();
		onCreate("deposit");
	};

	handleCreatePayoutClick = () => {
		const { onCreate } = this.props;
		this.forceHidePopover();
		onCreate("payout");
	};

	render() {
		const { stocking } = this.props;

		return (
			<React.Fragment>
				<div className="d-block d-md-none" onMouseEnter={this.handleMouseOverPopover} onMouseLeave={this.handleMouseLeavePopover}>
					<button
						ref={this.functionButton}
						className="btn btn-sm btn-secondary"
						style={{ paddingLeft: "5px", paddingRight: "1px" }}
						title="Funktionen"
						onClick={this.showPopover}
						onMouseEnter={this.showPopover}
						onMouseLeave={this.hidePopover}
					>
						<List className="me-1" size={20} />
					</button>
					<Popover trigger="manual" placement="right" isOpen={this.state.functionPopoverOpen} target={this.functionButton}>
						<PopoverBody className="bg-secondary">
							<StockingButtonGroup
								stocking={stocking}
								onSettingsClick={this.handleSettingsClick}
								onCreateDepositClick={this.handleCreateDepositClick}
								onCreatePayoutClick={this.handleCreatePayoutClick}
							/>
						</PopoverBody>
					</Popover>
				</div>
				<div className="d-none d-md-block">
					<StockingButtonGroup
						stocking={stocking}
						onSettingsClick={this.handleSettingsClick}
						onCreateDepositClick={this.handleCreateDepositClick}
						onCreatePayoutClick={this.handleCreatePayoutClick}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default StockingButtons;
