import React from "react";
import { CaretRightFill, CaretDownFill } from "react-bootstrap-icons";

const CollapsableEntry = (props) => {
	const { value, collapsed, onToggleCollapse } = props;
	return (
		<div className={"me-3" + (collapsed ? "" : " fw-bold")}>
			{collapsed && <CaretRightFill className="me-1" size={12} onClick={onToggleCollapse} />}
			{!collapsed && <CaretDownFill className="me-1" size={12} onClick={onToggleCollapse} />}
			{value}
		</div>
	);
};

export default CollapsableEntry;
