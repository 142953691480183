import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import ApplicationContext from "../services/applicationContext";
import ResetPasswordForm from "./forms/resetPasswordForm";

const ResetPassword = (props) => {
	const context = useContext(ApplicationContext);
	if (context.user) return <Redirect to="/app/start" />;

	return (
		<React.Fragment>
			<div className="row mx-3">
				<div className="col-md-2 col-lg-3 col-xl-3"></div>
				<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
					<center>
						<h1 className="mt-5">Passwort zurücksetzen</h1>
						<p className="mt-5 lead">Bitte vergebe für Deinen Sparstrumpf Account nun ein neues Passwort!</p>
						<p className="mt-4 mb-5 small justify">
							Dieser Link ist maximal eine Stunde gültig. Sollte ein Fehler passieren, beginne den Prozess bitte <Link to="/vergessen">neu</Link>.
							Bei Fragen erreichst Du uns am schnellsten über das <Link to="/kontakt">Kontaktformular</Link>.
						</p>
					</center>

					<ResetPasswordForm />
				</div>
				<div className="col-auto"> </div>
			</div>
		</React.Fragment>
	);
};

export default ResetPassword;
