import React, { Component } from "react";
import { BoxArrowInRight, BoxArrowRight, PlusCircle } from "react-bootstrap-icons";
import TransactionTable from "../../common/transactionTable";
import TransactionPopover from "../transactionPopover";

class PlateHeader extends Component {
	depositBadge = React.createRef();
	payoutBadge = React.createRef();

	buttonStyle = { cursor: "pointer" };

	getBadgeClasses = (unprocessed) => {
		const { isCurrentMonth, isFuture } = this.props;
		const base = "badge rounded-pill ms-2 ";

		let textColor = "";
		if (isFuture) textColor = " text-muted";
		else if (!unprocessed) textColor = " text-dark";

		let bgColor = "bg-light ";
		if (isCurrentMonth && unprocessed) bgColor = "bg-primary ";
		else if (!isFuture && unprocessed) bgColor = "bg-danger ";

		return base + bgColor + textColor;
	};

	getBadgeStyle = (count) => {
		if (count > 0) return { cursor: "pointer" };
		else return { cursor: "default" };
	};

	getDepositBorderClasses = () => {
		return "w-50 px-1 border-end border-secondary";
	};

	getPayoutsBorderClasses = () => {
		return "w-50 px-1" + (this.props.display === "payouts" ? " border-end border-secondary" : "");
	};

	toggleDepositPopover = () => {
		this.setState({ payoutBadgePopoverOpen: false, depositBadgePopoverOpen: !this.state.depositBadgePopoverOpen });
	};

	togglePayoutPopover = () => {
		this.setState({ depositBadgePopoverOpen: false, payoutBadgePopoverOpen: !this.state.payoutBadgePopoverOpen });
	};

	handleDepositTransactionDelete = (transactionId, editRecurring) => {
		this.props.onTransactionDelete(transactionId, editRecurring);
		if (this.props.deposits <= 1) this.setState({ depositBadgePopoverOpen: false });
	};

	handlePayoutTransactionDelete = (transactionId, editRecurring) => {
		this.props.onTransactionDelete(transactionId, editRecurring);
		if (this.props.payouts <= 1) this.setState({ payoutBadgePopoverOpen: false });
	};

	handleDepositTransactionMoveForward = (transactionId) => {
		this.props.onTransactionMoveForward(transactionId);
		if (this.props.deposits <= 1) this.setState({ depositBadgePopoverOpen: false });
	};

	handleDepositTransactionMoveBackward = (transactionId) => {
		this.props.onTransactionMoveBackward(transactionId);
		if (this.props.deposits <= 1) this.setState({ depositBadgePopoverOpen: false });
	};

	handlePayoutTransactionMoveForward = (transactionId) => {
		this.props.onTransactionMoveForward(transactionId);
		if (this.props.payouts <= 1) this.setState({ payoutBadgePopoverOpen: false });
	};

	handlePayoutTransactionMoveBackward = (transactionId) => {
		this.props.onTransactionMoveBackward(transactionId);
		if (this.props.payouts <= 1) this.setState({ payoutBadgePopoverOpen: false });
	};

	handlePopoverColorChange = (popoverColor) => {
		this.setState({ popoverColor });
	};

	state = {
		depositBadgePopoverOpen: false,
		payoutBadgePopoverOpen: false,
		popoverColor: null,
	};

	render() {
		const { popoverColor } = this.state;
		const {
			display,
			currency,
			transactions,
			deposits,
			payouts,
			unprocessedDeposits,
			unprocessedPayouts,
			onTransactionProcessed,
			onTransactionNotProcessed,
			onTransactionChangeDescription,
			onTransactionChangeValue,
			onCreate,
		} = this.props;

		const depositTransactions = transactions.filter((t) => t.type === "deposit").sort((a, b) => a.description.localeCompare(b.description));
		const payoutTransactions = transactions.filter((t) => t.type === "payout").sort((a, b) => a.description.localeCompare(b.description));

		return (
			<div className="d-flex text-muted small" style={{ marginBottom: "2px" }}>
				{display !== "payouts" && (
					<div className={this.getDepositBorderClasses()}>
						<BoxArrowInRight />
						<span
							ref={this.depositBadge}
							className={this.getBadgeClasses(unprocessedDeposits)}
							style={this.getBadgeStyle(deposits)}
							onClick={deposits > 0 ? this.toggleDepositPopover : null}
						>
							{deposits}
						</span>
						<TransactionPopover
							isOpen={this.state.depositBadgePopoverOpen}
							target={this.depositBadge}
							onClose={this.toggleDepositPopover}
							title="Einzahlungen"
							isWithinModal={false}
							color={popoverColor}
							render={() => (
								<TransactionTable
									currency={currency}
									transactions={depositTransactions}
									onProcessed={onTransactionProcessed}
									onNotProcessed={onTransactionNotProcessed}
									onMoveForward={this.handleDepositTransactionMoveForward}
									onMoveBackward={this.handleDepositTransactionMoveBackward}
									onDelete={this.handleDepositTransactionDelete}
									onChangeDescription={onTransactionChangeDescription}
									onChangeValue={onTransactionChangeValue}
									onColorChange={this.handlePopoverColorChange}
								/>
							)}
						/>
					</div>
				)}
				{display !== "deposits" && (
					<div className={this.getPayoutsBorderClasses()}>
						<BoxArrowRight />
						<span
							ref={this.payoutBadge}
							className={this.getBadgeClasses(unprocessedPayouts)}
							style={this.getBadgeStyle(payouts)}
							onClick={payouts > 0 ? this.togglePayoutPopover : null}
						>
							{payouts}
						</span>

						<TransactionPopover
							isOpen={this.state.payoutBadgePopoverOpen}
							target={this.payoutBadge}
							onClose={this.togglePayoutPopover}
							title="Auszahlungen"
							isWithinModal={false}
							color={popoverColor}
							render={() => (
								<TransactionTable
									currency={currency}
									transactions={payoutTransactions}
									factor={-1}
									onProcessed={onTransactionProcessed}
									onNotProcessed={onTransactionNotProcessed}
									onMoveForward={this.handlePayoutTransactionMoveForward}
									onMoveBackward={this.handlePayoutTransactionMoveBackward}
									onDelete={this.handlePayoutTransactionDelete}
									onChangeDescription={onTransactionChangeDescription}
									onChangeValue={onTransactionChangeValue}
									onColorChange={this.handlePopoverColorChange}
								/>
							)}
						/>
					</div>
				)}
				{display !== "both" && (
					<div title={display === "deposits" ? "Neue Einzahlung" : "Neue Auszahlung"}>
						<PlusCircle
							className="ms-1 text-primary"
							style={this.buttonStyle}
							onClick={() => onCreate(display === "payouts" ? "payout" : "deposit")}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default PlateHeader;
