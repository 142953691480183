import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import ApplicationContext from "../services/applicationContext";
import "../css/App.css";
import "../css/Nav.css";
import NavElement from "./common/navElement";
import Auth from "../services/authService";
import reports from "../services/reports";

class NavBar extends Component {
	static contextType = ApplicationContext;
	navBarRef = React.createRef();
	togglerRef = React.createRef();

	handleLogout = async (e) => {
		e.preventDefault();
		await Auth.logout(this.context);
		this.props.history.push("/");
	};

	getUserDropDown = () => {
		const { user } = this.context;
		const pathname = this.props.location.pathname;
		const isActive = pathname && pathname.startsWith("/app/user");

		return (
			<li className="nav-item dropdown">
				<button
					className="btn btn-link nav-link dropdown-toggle"
					id="navbarDropdown"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					style={{ color: isActive ? "var(--bs-navbar-active-color)" : "" }}
				>
					{user.name}
				</button>
				<ul className="dropdown-menu dropdown-menu-end App-contrast-background" aria-labelledby="navbarDropdown" style={{ width: "140px" }}>
					{this.getUserMenu("dropdown")}
				</ul>
			</li>
		);
	};

	getUserMenu = (type = "menu") => {
		return (
			<React.Fragment>
				<NavElement type={type} label="Profil" to="/app/user/profil" onSelect={this.toggleNavBar} />
				<NavElement type={type} label="Abonnement" to="/app/user/abo" disabled />
				{type === "dropdown" && (
					<li>
						<hr className="dropdown-divider" />
					</li>
				)}
				<NavElement type={type} label="Logout" to="/logout" onSelect={this.hideNavBar} onClick={this.handleLogout} />
			</React.Fragment>
		);
	};

	getReportsDropDown = () => {
		const pathname = this.props.location.pathname;
		const isActive = pathname && pathname.startsWith("/app/berichte");

		return (
			<li className="nav-item dropdown">
				<button
					className="btn btn-link nav-link dropdown-toggle"
					id="reportsDropdown"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					style={{ color: isActive ? "var(--bs-navbar-active-color)" : "" }}
				>
					Berichte
				</button>
				<ul className="dropdown-menu dropdown-menu-start App-contrast-background" aria-labelledby="reportsDropdown" style={{ width: "195px" }}>
					{this.getReportsMenu("dropdown")}
				</ul>
			</li>
		);
	};

	getReportsMenu = (type = "menu") => {
		return (
			<React.Fragment>
				{type === "menu" && (
					<li>
						<hr className="dropdown-divider" />
					</li>
				)}
				{reports.map((report) => (
					<NavElement key={report.key} type={type} label={report.title} to={"/app/berichte/" + report.key} onSelect={this.toggleNavBar} />
				))}
				{type === "menu" && (
					<li>
						<hr className="dropdown-divider" />
					</li>
				)}
			</React.Fragment>
		);
	};

	getNavBarClasses = () => {
		const base = "navbar sticky-top navbar-light shadow-sm App-contrast-background ";
		return !this.context.user ? base + "navbar-expand" : base + "navbar-expand-sm";
	};

	toggleNavBar = () => {
		const theToggler = this.togglerRef.current;
		if (theToggler.getAttribute("aria-expanded") === "true") {
			theToggler.click();
		}
	};

	hideNavBar = () => {
		const theNavBar = this.navBarRef.current;
		theNavBar.classList.remove("show");
	};

	render() {
		const { user } = this.context;
		return (
			<nav className={this.getNavBarClasses()}>
				<div className="container-fluid">
					<Link className="navbar-brand pt-1" to="/">
						<img src="../images/logo.png" alt="Logo" />
					</Link>
					<button
						ref={this.togglerRef}
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse text-end" id="navbarNav" ref={this.navBarRef}>
						<ul className="navbar-nav">
							{user && (
								<React.Fragment>
									<li className="nav-item" onClick={this.toggleNavBar}>
										<NavLink className="nav-link" to="/app/start">
											Start
										</NavLink>
									</li>
									<div className="d-none d-sm-block" style={{ marginTop: 1 }}>
										{this.getReportsDropDown()}
									</div>
									<div className="d-block d-sm-none">
										<li className="nav-item" onClick={this.toggleNavBar}>
											<NavLink className="nav-link" to="/app/berichte">
												Berichte
											</NavLink>
										</li>
									</div>
								</React.Fragment>
							)}
						</ul>
						<div className="navbar-nav flex-grow-1 justify-content-end">
							<div className="d-none d-sm-block">{user && this.getUserDropDown()}</div>
							<div className="d-block d-sm-none">{user && this.getUserMenu()}</div>
							{!user && (
								<li className="nav-item">
									<NavLink className="nav-link" to="/registrieren">
										Registrieren
									</NavLink>
								</li>
							)}
							{!user && (
								<li className="nav-item">
									<NavLink className="nav-link" to="/login">
										Einloggen
									</NavLink>
								</li>
							)}
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

const NavBarWithRouter = withRouter(NavBar);

export default NavBarWithRouter;
