import http from "./httpService";

async function getAccounts() {
	const { data: accounts } = await http.get("accounts");
	return accounts;
}

async function createAccount(accountData) {
	const { data: account } = await http.post("accounts", accountData);
	return account;
}

async function initAccount(accountId, initAccountData) {
	return http.post("accounts/" + accountId + "/init", initAccountData);
}

async function getQrCode(accountId, transactionGroupId) {
	const { data: qrcode } = await http.get("accounts/" + accountId + "/qrcodes/" + transactionGroupId);
	return qrcode;
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const accountService = {
	getAccounts,
	createAccount,
	initAccount,
	getQrCode,
	handleExpectedErrors,
};

export default accountService;
