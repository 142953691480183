import http from "./httpService";
import Format from "./formattingService";

async function createRecurring(accountId, stockingId, recurringRequest) {
	return http.post("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings", recurringRequest);
}

async function updateRecurring(accountId, stockingId, recurringId, changes, modificationDate) {
	if (modificationDate) {
		const from = Format.isodate(modificationDate);
		return http.patch("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings/" + recurringId + "?from=" + from, changes);
	} else {
		return http.patch("accounts/" + accountId + "/stockings/" + stockingId + "/recurrings/" + recurringId, changes);
	}
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const recurringService = {
	createRecurring,
	updateRecurring,
	handleExpectedErrors,
};

export default recurringService;
