import http from "./httpService";

async function login(context, credentials) {
	const response = await http.post("auth/login", credentials);
	const { token, user } = response.data;
	http.setJwt(token);
	sessionStorage.setItem("token", token);
	context.handleLogin(user);
}

async function logout(context) {
	await http.post("auth/logout");
	http.setJwt(null);
	sessionStorage.removeItem("token");
	context.handleLogout();
}

async function createUser(userData) {
	return http.post("users", userData);
}

async function getCurrentUser() {
	try {
		const { data: user } = await http.get("users/me");
		return user;
	} catch (error) {
		return null;
	}
}

async function verifyUser(token) {
	return http.get("users/verify", { params: { token } });
}

async function forgotPassword(data) {
	return http.post("users/forgot", data);
}

async function resetPassword(data) {
	return http.post("users/reset", data);
}

function getJwt() {
	return sessionStorage.getItem("token");
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const authService = {
	login,
	logout,
	createUser,
	getCurrentUser,
	verifyUser,
	forgotPassword,
	resetPassword,
	getJwt,
	handleExpectedErrors,
};

http.setJwt(getJwt());

export default authService;
