const Banner = (props) => {
	const { children, color = "warning" } = props;
	return (
		<div className={"text-center alert alert-" + color} role="alert">
			{children}
		</div>
	);
};

export default Banner;
