import React from "react";
import BaseReport from "./baseReport";
import Format from "../../../services/formattingService";

class DepositsAndPayouts extends BaseReport {
	sort = { key: "month", order: "asc" };

	lineChart = {
		title: { text: "Ein- und Auszahlungen" },
		yAxis: {
			title: { text: "" },
		},
		xAxis: {
			type: "category",
		},
	};

	// @Overwrite
	handleData = (data) => {
		const { account } = this.props;
		const { currency } = account;

		// TABLE
		// set the column definition
		const columns = [
			{ label: "Monat", path: "month", content: (d) => <React.Fragment>{Format.date(new Date(d.month))}</React.Fragment> },
			{
				label: "Einzahlungen",
				path: "deposits",
				align: "end",
				content: (d) => <React.Fragment>{Format.money(d.deposits || 0, currency)}</React.Fragment>,
			},
			{
				label: "Auszahlungen",
				path: "payouts",
				align: "end",
				content: (d) => <React.Fragment>{Format.money(d.payouts || 0, currency)}</React.Fragment>,
			},
		];

		// calculate table footer
		var sumPayouts = 0;
		var sumDeposits = 0;
		data.forEach((e) => {
			sumPayouts += e.payouts;
			sumDeposits += e.deposits;
		});
		const footer = ["Gesamt", Format.money(sumDeposits, currency), Format.money(sumPayouts, currency)];

		// LINECHART
		// set the series data
		this.lineChart.series = [
			{ name: "Einzahlungen", data: data.map((e) => e.deposits) },
			{ name: "Auszahlungen", data: data.map((e) => e.payouts) },
		];

		// add the yAxis formatting
		this.lineChart.yAxis.labels = {
			formatter: function () {
				return Format.money(this.value || 0, currency);
			},
		};

		// set the xAxis values
		this.lineChart.xAxis.categories = data.map((e) => Format.date(new Date(e.month)));

		// modify the tooltip
		this.lineChart.tooltip = {
			pointFormatter: function () {
				return "<span style='color:" + this.color + "'>\u25CF</span>&nbsp;" + this.series.name + ": <b>" + Format.money(this.y, currency) + "</b><br/>";
			},
		};

		//console.log("chart", this.chart);
		this.setState({ data, columns, footer, sort: this.sort, rightLineChart: this.lineChart });
	};
}

export default DepositsAndPayouts;
