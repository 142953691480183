import React, { Component } from "react";
import PropTypes from "prop-types";

class TableHeader extends Component {
	raiseSort = (sortKey) => {
		if (sortKey != null) {
			let sort = { ...this.props.sort };
			if (sort.key === sortKey) sort.order = sort.order === "asc" ? "desc" : "asc";
			else sort = { key: sortKey, order: "asc" };
			this.props.onSort(sort);
		}
	};

	renderSortIcon(column) {
		const { sort } = this.props;
		if (sort.key !== column.path) return null;
		if (sort.order === "asc") return <i className="fa fa-sort-asc" aria-hidden="true"></i>;
		return <i className="fa fa-sort-desc" aria-hidden="true"></i>;
	}

	getCellClasses = (column) => {
		const align = column.align || "start";
		return "text-" + align + (column.path ? " clickable" : "");
	};

	render() {
		const { columns } = this.props;

		return (
			<thead>
				<tr>
					{columns.map((column) => (
						<th className={this.getCellClasses(column)} key={column.path || column.key} onClick={() => this.raiseSort(column.path)} scope="col">
							{column.label} {this.renderSortIcon(column)}
						</th>
					))}
				</tr>
			</thead>
		);
	}
}

export default TableHeader;

TableHeader.propTypes = {
	sort: PropTypes.object.isRequired,
	onSort: PropTypes.func.isRequired,
	columns: PropTypes.array.isRequired,
};
