export function generateMonthArray(minus, plus) {
	const currentMonth = new Date();
	const thisMonth = currentMonth.getMonth();
	currentMonth.setDate(1);
	currentMonth.setHours(0);
	currentMonth.setMinutes(0);
	currentMonth.setSeconds(0);

	const months = [];
	for (let i = minus; i < plus; i++) {
		const d = new Date(currentMonth);
		d.setMonth(i + thisMonth);
		months.push(d);
	}
	return months;
}

export function isSameMonth(date1, date2) {
	return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export function isFuture(month) {
	return new Date().getTime() < month.getTime();
}

export function getMonth(date1) {
	const month = new Date(date1);
	month.setDate(1);
	month.setHours(0);
	month.setMinutes(0);
	month.setSeconds(0);
	return month;
}
