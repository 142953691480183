import React from "react";
import Form from "../common/forms/form";
import Validation from "../../services/validation";
import Account from "../../services/accountService";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import Format from "../../services/formattingService";
import AccordionItem from "../common/accordionItem";
import BalanceDistributionSubform from "./balanceDistributionSubform";

class OpenBalanceForm extends Form {
	static contextType = ApplicationContext;

	state = {
		// @Override
		data: {
			openBalance: 0,
			stockings: [],
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.openBalanceFormSchema;

	// @Override
	doSubmit = async () => {
		const { account, onSubmitted } = this.props;
		const data = this.state.data.stockings
			.filter((stocking) => stocking.editing !== true)
			.map((stocking) => ({ ...stocking, balance: Number(stocking.balance) }));

		try {
			await Account.initAccount(account.accountId, data);
			toast.success("Dein Guthaben wurde erfolgreich verteilt.");
			onSubmitted(account);
		} catch (ex) {
			console.log(ex);
			Account.handleExpectedErrors(ex, this);
		}
	};

	handleStockingChange = (e, index) => {
		const { name, value } = e.currentTarget;
		const data = { ...this.state.data };
		const stockingData = data.stockings[index];
		stockingData[name] = value;

		data.openBalance = this.calculateOpenBalance(data);
		this.setState({ data });
	};

	handleAddStocking = (index) => {
		const data = { ...this.state.data };
		data.stockings[index].balance = data.openBalance;
		delete data.stockings[index].editing;

		data.openBalance = this.calculateOpenBalance(data);
		this.addStockingTemplate(data.stockings);

		this.setState({ data });
	};

	renderSubform = (index) => {
		return (
			<BalanceDistributionSubform
				data={this.state.data.stockings[index]}
				onChange={(e) => this.handleStockingChange(e, index)}
				onSubmitted={() => this.handleAddStocking(index)}
			/>
		);
	};

	addStockingTemplate = (stockings) => {
		stockings.push({ name: "", type: "automatic", balance: 0, editing: true });
	};

	calculateOpenBalance = (data) => {
		let sum = 0;
		data.stockings.forEach((stocking) => (sum += Number(stocking.balance)));
		return Math.round(100 * (this.props.account.openBalance - sum)) / 100;
	};

	componentDidMount() {
		const data = { ...this.state.data };
		data.openBalance = this.calculateOpenBalance(data);
		this.addStockingTemplate(data.stockings);
		this.setState({ data });
	}

	// @Override
	render() {
		const { errorMessage, /*errors, */ data } = this.state; // we don't support field based errors here
		const { account } = this.props;

		return (
			<React.Fragment>
				<div className="mb-4">
					Du hast Guthaben auf Deinem Basiskonto, welches nun auf ein oder mehrere Sparstrümpfe verteilt werden muss. Du kannst dafür jetzt die ersten
					Strümpfe anlegen und das Geld aufteilen.
				</div>
				<div className="mb-4">
					<b>Zu verteilen:</b> {Format.money(data.openBalance, account.currency)}
				</div>
				<form onSubmit={this.handleSubmit}>
					<div className="row">
						<div className="accordion mb-4" id="stockings">
							{data.stockings.map((stocking, index) => (
								<AccordionItem
									key={index}
									parentId="stockings"
									id={index}
									title={stocking.name || "Neu..."}
									render={() => this.renderSubform(index)}
									show={index === Math.max(0, this.state.data.stockings.length - 2)}
								/>
							))}
						</div>

						{errorMessage && (
							<div className="alert alert-danger" role="alert">
								{errorMessage}
							</div>
						)}
						<button type="submit" className="btn btn-primary" disabled={this.validate()}>
							Speichern
						</button>

						<div className="form-text mt-2">Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter Datenschutz.</div>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default OpenBalanceForm;
