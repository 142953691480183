import React from "react";
import ListCardEntry from "./listCardEntry";
import { Link } from "react-router-dom";

const ListCard = (props) => {
	const { title, items, link } = props;
	const height = { height: 15 };

	return (
		<div className="card shadow align-self-start me-sm-4 me-lg-5">
			<div className="card-header">
				<span className="d-none d-lg-inline-block me-3 h5" style={height}>
					{title}
				</span>
				<span className="d-inline-block d-lg-none me-2 fw-bold fs-6" style={height}>
					{title}
				</span>
				{link && link.onClick && (
					<span className="text-primary text-decoration-underline small clickable ms-1" onClick={link.onClick}>
						{link.label}
					</span>
				)}
				{link && link.to && (
					<Link className="small" to={link.to}>
						{link.label}
					</Link>
				)}
			</div>

			<ul className="list-group list-group-flush">
				{items
					.filter((item) => item.value !== undefined)
					.map((item) => {
						const { label, ...rest } = item;
						return <ListCardEntry key={label} label={label} {...rest} />;
					})}
			</ul>
		</div>
	);
};

export default ListCard;
