import React, { Component } from "react";

class TextArea extends Component {
	getNotice = () => {
		const { notice, maxLength, value } = this.props;

		if (notice) return notice;
		if (maxLength) return maxLength - value.length + " Zeichen übrig";
		else return "";
	};

	render() {
		const { name, label, error, ...rest } = this.props;
		return (
			<div className="mb-3">
				<label htmlFor={name} className="form-label">
					{label}
				</label>

				<textarea {...rest} id={name} name={name} type="text" className="form-control" />

				{error && <div className="form-text text-danger ms-1">{error}</div>}
				{!error && <div className="form-text text-end">{this.getNotice()}</div>}
			</div>
		);
	}
}

export default TextArea;
