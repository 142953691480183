import React, { Component } from "react";
import TransactionTableEntry from "./transactionTableEntry";
import TransactionTableGroupEntry from "./transactionTableGroupEntry";

class TransactionTable extends Component {
	state = {};
	render() {
		const {
			currency,
			factor,
			transactions,
			transactionGroups,
			stockings,
			account,
			onProcessed,
			onNotProcessed,
			onMoveBackward,
			onMoveForward,
			onDelete,
			onChangeDescription,
			onChangeValue,
			onColorChange,
		} = this.props;

		return (
			<div className="table-responsive">
				<table className="table table-sm">
					<tbody>
						{transactions &&
							transactions.map((transaction) => (
								<TransactionTableEntry
									key={transaction.id}
									currency={currency}
									transaction={transaction}
									factor={factor}
									onProcessed={() => onProcessed(transaction.id)}
									onNotProcessed={() => onNotProcessed(transaction.id)}
									onMoveForward={() => onMoveForward(transaction.id)}
									onMoveBackward={() => onMoveBackward(transaction.id)}
									onDelete={(r) => onDelete(transaction.id, r)}
									onChangeDescription={(v, r) => onChangeDescription(transaction.id, v, r)}
									onChangeValue={(v, r) => onChangeValue(transaction.id, v, r)}
									onColorChange={onColorChange}
								/>
							))}
						{transactionGroups &&
							transactionGroups.map((transactionGroup, idx) => (
								<TransactionTableGroupEntry
									key={idx}
									currency={currency}
									stockings={stockings}
									account={account}
									transactionGroup={transactionGroup}
									factor={factor}
								/>
							))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default TransactionTable;
