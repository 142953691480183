import React, { Component } from "react";
import "../css/disclaimer.css";

class Privacy extends Component {
	render() {
		return (
			<div className="m-3">
				<center>
					<h1 className="mt-5">Datenschutzerklärung</h1>
					<div className="privacy-div">
						<iframe title="Datenschutzerklärung" src="/datenschutz.html" height="100%" width="100%" />
					</div>
				</center>
			</div>
		);
	}
}

export default Privacy;
