import React, { Component } from "react";
import ListCard from "../common/listCard";
import { isSameMonth, isFuture } from "../../services/dateService";
import TransactionTable from "../common/transactionTable";

class MovementsCard extends Component {
	calculateStats = (unprocessedTransactions) => {
		const now = new Date();
		let unprocessedPayoutsValue = 0;
		let unprocessedDepositsValue = 0;
		let unprocessedDeposits = [];
		let unprocessedPastDeposits = [];
		let unprocessedPayouts = [];
		let unprocessedPastPayouts = [];

		if (unprocessedTransactions) {
			Object.keys(unprocessedTransactions).forEach((transactionId) => {
				const transactionGroup = unprocessedTransactions[transactionId];
				let total = 0;
				let isPast = false;
				let groupType = "deposit";
				Object.keys(transactionGroup).forEach((stockingId) => {
					const { value, bookingDate, type } = transactionGroup[stockingId];
					total += value;
					if (!isFuture(bookingDate) && !isSameMonth(bookingDate, now)) isPast = true;
					if (type === "payout") groupType = "payout";
				});

				if (groupType === "deposit") {
					unprocessedDepositsValue += total;
					if (isPast) {
						unprocessedPastDeposits.push(transactionGroup);
					} else {
						unprocessedDeposits.push(transactionGroup);
					}
				} else if (groupType === "payout") {
					unprocessedPayoutsValue -= total;
					if (isPast) {
						unprocessedPastPayouts.push(transactionGroup);
					} else {
						unprocessedPayouts.push(transactionGroup);
					}
				}
			});
		} else {
			unprocessedDepositsValue = null;
			unprocessedPayoutsValue = null;
		}

		return {
			unprocessedDepositsValue,
			unprocessedPayoutsValue,
			unprocessedDeposits,
			unprocessedPayouts,
			unprocessedPastDeposits,
			unprocessedPastPayouts,
		};
	};

	renderPopoverBody = (transactionGroups, factor) => {
		const { stockings, account } = this.props;
		const currency = account && account.currency;

		return <TransactionTable currency={currency} stockings={stockings} account={account} transactionGroups={transactionGroups} factor={factor} />;
	};

	render() {
		const { account, unprocessedTransactions } = this.props;
		const currency = account && account.currency;
		const stats = this.calculateStats(unprocessedTransactions);

		const items = [
			{
				label: "Einzahlungen",
				value: stats.unprocessedDepositsValue,
				currency: currency,
				badge: stats.unprocessedDeposits.length,
				badgePopoverTitle: "Einzahlungen",
				badgePopoverBody: () => this.renderPopoverBody(stats.unprocessedDeposits),
				errorBadge: stats.unprocessedPastDeposits.length,
				errorBadgePopoverTitle: "Überfällige Einzahlungen",
				errorBadgePopoverBody: () => this.renderPopoverBody(stats.unprocessedPastDeposits),
			},
			{
				label: "Auszahlungen",
				value: stats.unprocessedPayoutsValue,
				currency: currency,
				badge: stats.unprocessedPayouts.length,
				badgePopoverTitle: "Auszahlungen",
				badgePopoverBody: () => this.renderPopoverBody(stats.unprocessedPayouts, -1),
				errorBadge: stats.unprocessedPastPayouts.length,
				errorBadgePopoverTitle: "Überfällige Auszahlungen",
				errorBadgePopoverBody: () => this.renderPopoverBody(stats.unprocessedPastPayouts, -1),
			},
		];

		return <ListCard title="Offene Transaktionen" items={items} />;
	}
}

export default MovementsCard;
