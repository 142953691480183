import axios from "axios";
import logger from "./loggingService";
import { toast } from "react-toastify";
import errorMessages from "../conf/errors.json";

class HttpService {
	onForbidden = null;

	constructor() {
		axios.defaults.baseURL = "/api/";
		//axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://192.168.3.39:81/api/" : "/api/";

		axios.interceptors.response.use(null, async (error) => {
			const originalConfig = error.config;

			// check for accessToken Refresh
			if (originalConfig.url !== "auth/login" && originalConfig.url !== "verify") {
				if (error.response && error.response.status === 401 && !originalConfig._retry) {
					console.log("401 Received => Access Token expired"); // keep these logs in, in case someone wonders about the 401 errors
					originalConfig._retry = true;
					try {
						const { data: accessToken } = await this.post("auth/refresh", null);
						console.log("401 Received => Access Token refreshed"); // keep these logs in, in case someone wonders about the 401 errors
						this.setJwt(accessToken);
						originalConfig.headers["x-auth-token"] = accessToken;
						return axios(originalConfig); //retry with new access token
					} catch (err) {
						if (this.onForbidden && err.response && err.response.status === 403) this.onForbidden();
						return Promise.reject(err);
					}
				}
			}

			// check for unexpected errors
			const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
			if (!expectedError) {
				toast.error("Es ist ein unerwarteter Fehler aufgetreten: " + error.message);
				logger.logException(error);
			}

			return Promise.reject(error);
		});
	}

	setOnForbidden = (onForbidden) => {
		this.onForbidden = onForbidden;
	};

	setJwt = (jwt) => {
		if (jwt) {
			axios.defaults.headers.common["x-auth-token"] = jwt;
		} else {
			delete axios.defaults.headers.common["x-auth-token"];
		}
	};

	handleExpectedErrors = (ex, component) => {
		if (ex.response) {
			if (ex.response.status >= 400 && ex.response.status < 500) {
				const { errors, error, message } = ex.response.data;
				const errorMessage = errorMessages[error] + (message ? "( " + message + ")" : "");
				for (var key in errors) {
					errors[key] = errorMessages[errors[key]] || errors[key];
				}
				component.setState({ errorMessage, errors });
			}
		} else {
			toast.error("Es ist ein unerwarteter Fehler aufgetreten: " + ex.message);
			logger.logException(ex);
		}
	};

	get = axios.get;
	post = axios.post;
	put = axios.put;
	patch = axios.patch;
	delete = axios.delete;
}

const httpService = new HttpService();

export default httpService;
