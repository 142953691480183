import React, { Component } from "react";
import PropTypes from "prop-types";

class TableFooter extends Component {
	getCellClasses = (column) => {
		const align = column.align || "start";
		return "text-" + align;
	};

	render() {
		const { columns, data } = this.props;

		return (
			<tfoot>
				<tr>
					{columns.map((column, idx) => (
						<th className={this.getCellClasses(column)} key={column.path || column.key} scope="col">
							{data[idx]}
						</th>
					))}
				</tr>
			</tfoot>
		);
	}
}

export default TableFooter;

TableFooter.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
};
