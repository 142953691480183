import React from "react";
import EditableEntry from "./forms/editableEntry";
import TransactionActions from "./transactionActions";
import Validation from "../../services/validation";
import { getMonth } from "../../services/dateService";

const TransactionTableEntry = (props) => {
	const {
		currency,
		transaction,
		factor,
		onProcessed,
		onNotProcessed,
		onMoveBackward,
		onMoveForward,
		onDelete,
		onChangeDescription,
		onChangeValue,
		onColorChange,
	} = props;

	const { description, value, processed, bookingDate, parent } = transaction;
	const transactionIsFuture = getMonth(bookingDate) > Date.now();
	const transactionIsRecurring = parent === "Recurring";
	const transactionIsCMD = parent === "CalculatorMonthlyDeposit";

	return (
		<React.Fragment>
			<tr className="d-table-row d-sm-none">
				<td colSpan="3" className="fw-bold border-0">
					<EditableEntry
						value={description}
						disabled={false}
						validationSchema={Validation.schemas.descriptionFieldSchema}
						maxWidth="250px"
						truncate={true}
						editAcknowledgeDialog={transactionIsRecurring}
						onChange={onChangeDescription}
						onColorChange={onColorChange}
					/>
				</td>
			</tr>
			<tr>
				<td>
					<div className="d-none d-sm-block ps-1 me-3">
						<EditableEntry
							value={description}
							disabled={false}
							validationSchema={Validation.schemas.descriptionFieldSchema}
							maxWidth="250px"
							truncate={true}
							editAcknowledgeDialog={transactionIsRecurring}
							onChange={onChangeDescription}
							onColorChange={onColorChange}
						/>
					</div>
				</td>
				<td>
					<div>
						<EditableEntry
							currency={currency}
							value={value}
							factor={factor}
							disabled={processed}
							validationSchema={Validation.schemas.valueFieldSchema}
							align="end"
							editAcknowledgeDialog={transactionIsRecurring}
							onChange={onChangeValue}
							onColorChange={onColorChange}
						/>
					</div>
				</td>
				<td>
					<TransactionActions
						processed={processed}
						onProcessed={transactionIsFuture ? null : onProcessed}
						onNotProcessed={transactionIsFuture ? null : onNotProcessed}
						onMoveForward={transactionIsCMD ? null : onMoveForward}
						onMoveBackward={transactionIsCMD ? null : onMoveBackward}
						onDelete={onDelete}
						deleteAcknowledgeDialog={transactionIsRecurring}
					/>
				</td>
			</tr>
		</React.Fragment>
	);
};

export default TransactionTableEntry;
