import React from "react";

const Input = (props) => {
	const { reference, name, label, notice, error, ...rest } = props;

	return (
		<div className="mb-3">
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<input {...rest} ref={reference} name={name} id={name} className="form-control" />
			{error && <div className="form-text text-danger ms-1">{error}</div>}
			{!error && <div className="form-text ms-1">{notice}</div>}
		</div>
	);
};

export default Input;
