import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Select from "../common/forms/select";
import { isSameMonth } from "../../services/dateService";
import Format from "../../services/formattingService";
import Transaction from "../../services/transactionService";
import Validation from "../../services/validation";
import ApplicationContext from "../../services/applicationContext";
import errorMessages from "../../conf/errors.json";

class TransactionForm extends Form {
	static contextType = ApplicationContext;

	transactionTypes = [
		{ value: "deposit", label: "Einzahlung" },
		{ value: "payout", label: "Auszahlung" },
	];

	handleFormModeToggle = async () => {
		const { extended } = this.state;
		if (!extended) {
			this.loadExistingGroupsForSelectedMonth();
		}
		this.setState({ extended: !extended });
	};

	handleGroupChange = (e) => {
		const { groupIds } = this.state;
		this.handleChange(e);
		if (e.currentTarget.value !== "") {
			const newDescription = groupIds.find((g) => g.value === e.currentTarget.value).label;
			setTimeout(() => this.handleChange({ currentTarget: { name: "description", value: newDescription } }), 50);
		}
	};

	getInitialBookingDate = () => {
		return !this.props.month || isSameMonth(this.props.month, new Date()) ? new Date() : this.props.month;
	};

	loadExistingGroupsForSelectedMonth = async () => {
		const { data } = this.state;
		const { accountId } = this.props;
		try {
			const transactions = await Transaction.getTransactionsOfMonth(accountId, data.bookingDate);
			const groups = transactions.filter((t) => t.groupId.startsWith("TG")).map((t) => ({ value: t.groupId, label: t.description }));
			const uniqueGroups = [...new Map(groups.map((group) => [group["value"], group])).values()].sort((a, b) => a.label.localeCompare(b.label));
			this.setState({ groupIds: uniqueGroups });
		} catch (error) {
			console.log(error);
		}
	};

	state = {
		// @Override
		data: {
			type: this.props.transactionType || "",
			bookingDate: this.getInitialBookingDate(),
			groupId: "",
			description: "",
			value: 0,
		},
		errors: {},
		extended: false,
		groupIds: [],
	};

	// @Override
	getSchema = () => Validation.schemas.transactionFormSchema;

	// @Override
	doSubmit = async () => {
		const { data } = this.state;
		const { stockingId, accountId, onSubmitted } = this.props;

		const requestData = { ...data, groupId: data.groupId === "" ? null : data.groupId };
		requestData.bookingDate = Format.isodate(data.bookingDate);
		requestData.value = Math.round(requestData.value * 100) / 100;
		if (data.type === "payout") requestData.value = data.value * -1;

		try {
			const transaction = await Transaction.createTransaction(accountId, stockingId, requestData);
			onSubmitted(transaction);
		} catch (ex) {
			console.log(ex);
			Transaction.handleExpectedErrors(ex, this);
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data.bookingDate !== this.state.data.bookingDate) {
			if (!isSameMonth(new Date(prevState.data.bookingDate), new Date(this.state.data.bookingDate))) this.loadExistingGroupsForSelectedMonth();
		}
	}

	// @Override
	render() {
		const { errorMessage, errors, data, extended } = this.state;
		const hasLicense = this.context.getLicenseType();

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="small text-end text-primary" style={{ cursor: "pointer" }} onClick={this.handleFormModeToggle}>
					{extended ? "einfach" : "erweitert"}
				</div>

				{!extended && (
					<div className="mb-2">
						Typ: <span className="text-muted">{this.transactionTypes.find((t) => t.value === data.type).label}</span>
					</div>
				)}
				{extended && (
					<Select
						label="Typ"
						name="type"
						error={errors.type}
						type="text"
						value={data.type}
						options={this.transactionTypes}
						onChange={this.handleChange}
					/>
				)}

				{!extended && (
					<div className="mb-2">
						Datum: <span className="text-muted">{Format.date(new Date(data.bookingDate))}</span>
					</div>
				)}
				{extended && (
					<Input
						label="Datum"
						placeholder="Einzahlung"
						name="bookingDate"
						error={errors.bookingDate}
						type="date"
						value={Format.isodate(data.bookingDate)}
						onChange={this.handleChange}
					/>
				)}

				{extended && (
					<Select
						label="Gruppe"
						name="groupId"
						error={errors.type}
						type="text"
						value={data.groupId}
						options={[{ value: "", label: "Neue..." }, ...this.state.groupIds.map((g) => ({ value: g.value, label: "Kopiere: " + g.label }))]}
						onChange={this.handleGroupChange}
					/>
				)}

				<Input
					label="Beschreibung"
					placeholder={data.type === "deposit" ? "z.B. Sondereinzahlung" : "z.B. Anzahlung Urlaub"}
					name="description"
					error={errors.description}
					type="text"
					value={data.description}
					onChange={this.handleChange}
				/>
				<Input
					label="Betrag"
					placeholder=""
					name="value"
					error={errors.value}
					type="number"
					step="0.01"
					value={data.value}
					onClick={(e) => e.currentTarget.select()}
					onChange={this.handleChange}
				/>

				{errorMessage && (
					<div className="alert alert-danger" role="alert">
						{errorMessage}
					</div>
				)}
				<div title={!hasLicense ? errorMessages["license_expired"] : ""} className={"d-inline-block" + (!hasLicense ? " license-expired" : "")}>
					<button type="submit" className="btn btn-primary" disabled={!hasLicense || this.validate()}>
						Anlegen
					</button>
				</div>
			</form>
		);
	}
}

export default TransactionForm;
