import React from "react";
import { InfoCircleFill } from "react-bootstrap-icons";
import IconWithPopover from "../IconWithPopover";

const Radio = (props) => {
	const { name, label, value, notice, error, items, onChange, isWithinModal } = props;

	return (
		<div className="mb-3">
			<label htmlFor={name} className="form-label">
				{label}
			</label>

			{items.map((item) => {
				const { id, label, info } = item;
				return (
					<div className="form-check d-flex" key={id}>
						<input className="form-check-input me-2" type="radio" name={name} id={id} value={id} onChange={onChange} checked={value === id} />
						<label className="form-check-label" htmlFor={id}>
							{label}
						</label>
						{info && (
							<div className="ms-1 text-primary" style={{ marginTop: "-2px" }}>
								<IconWithPopover
									icon={InfoCircleFill}
									iconSize={15}
									isWithinModal={isWithinModal}
									popoverPlacement="top"
									popoverTitle={label}
									popoverRender={() => <div style={{ width: "200px" }}>{info}</div>}
								/>
							</div>
						)}
					</div>
				);
			})}

			{error && <div className="form-text text-danger ms-1">{error}</div>}
			{!error && <div className="form-text ms-1">{notice}</div>}
		</div>
	);
};

export default Radio;
