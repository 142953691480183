import React from "react";
import { Link } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Radio from "../common/forms/radio";
import radioItems from "../../conf/radioItems.json";
import Validation from "../../services/validation";
import Stocking from "../../services/stockingService";
import ApplicationContext from "../../services/applicationContext";
import Banner from "../common/Banner";
import errorMessages from "../../conf/errors.json";
import ColorPicker from "../common/forms/colorPicker";

class StockingForm extends Form {
	static contextType = ApplicationContext;
	presets = ["#23ac4f", "#678aaa", "#ac722d", "#f8e6a6", "#ffdb5b", "#c1967a", "#78b3dc", "#e59e2e", "#cccccc", "#00ff00", "#ff0000", "#0000ff"];
	focusRef = React.createRef();

	state = {
		// @Override
		data: {
			name: "",
			type: "automatic",
			stockingId: null,
			color: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.stockingFormSchema;

	// @Override
	doSubmit = () => {
		const { accountId, onSubmitted } = this.props;
		const { data } = this.state;

		if (data.stockingId) {
			this.doSubmitUpdate(accountId, data, onSubmitted);
		} else {
			this.doSubmitCreation(accountId, data, onSubmitted);
		}
	};

	doSubmitUpdate = async (accountId, data, onSubmitted) => {
		try {
			const changes = {};
			if (this.props.data.name !== data.name) changes.name = data.name;
			if (this.props.data.type !== data.type) changes.type = data.type;
			if (this.props.data.color !== data.color) changes.color = data.color;
			if (Object.keys(changes).length > 0) {
				const stocking = await Stocking.updateStocking(accountId, data.stockingId, changes);
				onSubmitted(stocking);
			} else {
				onSubmitted(null);
			}
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	doSubmitCreation = async (accountId, data, onSubmitted) => {
		try {
			const stocking = await Stocking.createStocking(accountId, data);
			onSubmitted(stocking);
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	componentDidMount() {
		const { data } = this.props;
		if (data) {
			this.setState({ data }, () => {
				this.focusRef.current && this.focusRef.current.focus();
			});
		} else {
			setTimeout(() => this.focusRef.current && this.focusRef.current.focus(), 10);
		}
	}

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const { buttonLabel } = this.props;
		const hasLicense = this.context.getLicenseType();

		return (
			<>
				{!this.context.getLicenseType() && (
					<Banner>
						Bitte verlängere <Link to="/abo">Dein Abo</Link> um diese Funktion zu nutzen.
					</Banner>
				)}
				<div className="m-2">
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col">
								<Input
									reference={this.focusRef}
									label="Sparstrumpf-Name"
									placeholder="z.B. Urlaub"
									name="name"
									error={errors.name}
									type="text"
									value={data.name}
									onChange={this.handleChange}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<Radio
									label="Sparstrumpf-Typ"
									name="type"
									isWithinModal={true}
									error={errors.type}
									value={data.type}
									items={radioItems.stockingTypes}
									onChange={this.handleChange}
								/>
							</div>
							<div className="col">
								<ColorPicker
									label="Farbe"
									name="color"
									error={errors.color}
									value={data.color || "#23ac4f"}
									presets={this.presets}
									onChange={this.handleChange}
								/>
							</div>
						</div>
						{errorMessage && (
							<div className="alert alert-danger" role="alert">
								{errorMessage}
							</div>
						)}
						<div title={!hasLicense ? errorMessages["license_expired"] : ""} className={"d-inline-block" + (!hasLicense ? " license-expired" : "")}>
							<button type="submit" className="btn btn-primary" disabled={!hasLicense || this.validate()}>
								{buttonLabel}
							</button>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default StockingForm;
