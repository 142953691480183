import React, { Component } from "react";
import TransactionPopover from "../protected/transactionPopover";

class IconWithPopover extends Component {
	iconRef = React.createRef();
	pointerCursor = { cursor: "pointer" };
	//defaultCursor = { cursor: "default" };

	// getBadgeClasses = () => {
	// 	const { className } = this.props;
	// 	return className + " badge rounded-pill";
	// };

	// getBadgeStyle = () => {
	// 	const { value } = this.props;
	// 	if (value > 0) return this.pointerCursor;
	// 	else return this.defaultCursor;
	// };

	togglePopover = () => {
		this.setState({ popoverStaysOpen: !this.state.popoverStaysOpen, popoverOpen: !this.state.popoverStaysOpen });
	};

	handleMouseOverPopover = () => {
		this.setState({ mouseOverPopover: true });
	};

	handleMouseLeavePopover = () => {
		this.setState({ mouseOverPopover: false, popoverOpen: this.state.popoverStaysOpen || false });
	};

	hidePopover = () => {
		if (!this.state.mouseOverPopover && !this.state.popoverStaysOpen) this.setState({ popoverOpen: false });
	};

	forceHidePopover = () => {
		this.setState({ popoverStaysOpen: false, popoverOpen: false });
	};

	showPopover = () => {
		this.setState({ popoverOpen: true });
	};

	state = {
		popoverOpen: false,
		popoverStaysOpen: false,
		mouseOverPopover: false,
	};

	render() {
		const { icon: Icon, iconSize, isWithinModal, popoverPlacement, popoverTitle, popoverRender } = this.props;
		return (
			<div className="d-inline-block" onMouseEnter={this.handleMouseOverPopover} onMouseLeave={this.handleMouseLeavePopover}>
				<span
					ref={this.iconRef}
					style={this.pointerCursor}
					onClick={this.togglePopover}
					onMouseEnter={this.showPopover}
					onMouseLeave={this.hidePopover}
				>
					<Icon size={iconSize} />
				</span>
				<TransactionPopover
					isOpen={this.state.popoverOpen}
					target={this.iconRef}
					onClose={this.forceHidePopover}
					placement={popoverPlacement}
					title={popoverTitle}
					isWithinModal={isWithinModal}
					render={popoverRender}
				/>
			</div>
		);
	}
}

export default IconWithPopover;
