import React from "react";
import AccountCard from "./accountCard";
import MovementsCard from "./movementsCard";

const CardHeader = (props) => {
	const { stockings, account, accountValue, unprocessedTransactions, handleAccountCreate } = props;
	return (
		<div className="row px-4 gy-4 px-sm-0">
			<div className="p-0 col-sm-6 col-md-5 col-lg-4">
				<AccountCard account={account} accountValue={accountValue} handleAccountCreate={handleAccountCreate} />
			</div>
			<div className="p-0 col-sm-6 col-md-5 col-lg-4">
				<MovementsCard stockings={stockings} account={account} unprocessedTransactions={unprocessedTransactions} />
			</div>
			<div className="col-sm-0 col-md-2 col-lg-4"></div>
		</div>
	);
};

export default CardHeader;
