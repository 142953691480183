import http from "./httpService";

async function submitSupportContact(contactData) {
	return http.post("support/contacts", contactData);
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const supportService = {
	submitSupportContact,
	handleExpectedErrors,
};

export default supportService;
