import React from "react";

const Configurator = (props) => {
	const { display, onChange } = props;

	return (
		<div className="d-flex justify-content-center pt-3 mt-5 ">
			<div className="btn-group" role="group" aria-label="Ansicht">
				<input
					type="radio"
					className="btn-check"
					name="btnradio"
					id="deposits"
					autoComplete="off"
					checked={display === "deposits"}
					onChange={onChange}
				/>
				<label className="btn btn-outline-primary" htmlFor="deposits">
					Einzahlungen
				</label>

				<input type="radio" className="btn-check" name="btnradio" id="payouts" autoComplete="off" checked={display === "payouts"} onChange={onChange} />
				<label className="btn btn-outline-primary" htmlFor="payouts">
					Auszahlungen
				</label>

				<input type="radio" className="btn-check" name="btnradio" id="both" autoComplete="off" checked={display === "both"} onChange={onChange} />
				<label className="btn btn-outline-primary" htmlFor="both">
					Beides
				</label>
			</div>
		</div>
	);
};

export default Configurator;
