import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavElement = (props) => {
	const { type, disabled, label, onClick, to, onSelect } = props;
	return (
		<React.Fragment>
			{type === "dropdown" && !onClick && (
				<li>
					<Link className={"dropdown-item" + (disabled ? " disabled" : "")} to={to}>
						{label}
					</Link>
				</li>
			)}
			{type === "dropdown" && onClick && (
				<li>
					<button className={"btn btn-link dropdown-item" + (disabled ? " disabled" : "")} onClick={onClick}>
						{label}
					</button>
				</li>
			)}
			{type === "menu" && (
				<li className="nav-item" onClick={onSelect || null}>
					<NavLink className={"nav-link" + (disabled ? " disabled" : "")} to={to} onClick={onClick}>
						{label}
					</NavLink>
				</li>
			)}
		</React.Fragment>
	);
};

export default NavElement;
