import React from "react";
import BaseReport from "./baseReport";
import Format from "../../../services/formattingService";

class TransactionList extends BaseReport {
	sort = { key: "bookingDate", order: "asc" };

	lineChart = {
		title: { text: "Saldo-Verlauf" },
		yAxis: {
			title: { text: "" },
		},
		xAxis: {
			type: "datetime",
		},
		chart: { height: 300 },
		legend: { enabled: false },
	};

	// @Overwrite
	handleDropDownChange = (e) => {
		const { value } = e.currentTarget;
		this.setState({ dropDownFilterValue: value }, this.refreshData);
	};

	refreshData = () => {
		const { originalData, dropDownFilterValue } = this.state;
		const { account } = this.props;
		const { currency } = account;

		const balances = originalData[0];
		const transactions = originalData[1].filter((t) => dropDownFilterValue === " " || t.stockingId === dropDownFilterValue);

		// Build Up Balances
		var startBalance = 0;
		balances.filter((b) => dropDownFilterValue === " " || b.stockingid === dropDownFilterValue).forEach((b) => (startBalance += b.balance));
		transactions.forEach((t) => (t.balance = startBalance += t.value));

		// TABLE
		// set the column definition
		const columns = [
			{
				label: "Datum",
				path: "bookingDate",
				content: (d) => <div className={d.processed ? "" : "text-muted"}>{Format.date(new Date(d.bookingDate))}</div>,
			},
			{ label: "Sparstrumpf", path: "stockingName", content: (d) => <div className={d.processed ? "" : "text-muted"}>{d.stockingName}</div> },
			{ label: "Beschreibung", path: "description", content: (d) => <div className={d.processed ? "" : "text-muted"}>{d.description}</div> },
			{
				label: "Einzahlung",
				// path: "value",
				key: "deposit",
				align: "end",
				content: (d) => <div className={d.processed ? "" : "text-muted"}>{d.type === "deposit" ? Format.money(d.value || 0, currency) : ""}</div>,
			},
			{
				label: "Auszahlung",
				// path: "value",
				key: "payout",
				align: "end",
				content: (d) => <div className={d.processed ? "" : "text-muted"}>{d.type === "payout" ? Format.money(-1 * d.value || 0, currency) : ""}</div>,
			},
			{
				label: "Saldo",
				key: "balance",
				align: "end",
				content: (d) => <div className={d.processed ? "" : "text-muted"}>{Format.money(d.balance || 0, currency)}</div>,
			},
		];

		// calculate table footer
		var sumPayouts = 0;
		var sumDeposits = 0;
		transactions.forEach((t) => {
			if (t.type === "deposit") sumDeposits += t.value;
			if (t.type === "payout") sumPayouts += -1 * t.value;
		});
		const footer = ["", "", "Gesamt", Format.money(sumDeposits, currency), Format.money(sumPayouts, currency), ""];

		// LINECHART
		// set the series data
		this.lineChart.series = [
			{ marker: { enabled: false }, name: "Saldo", data: transactions.map((e, idx) => [new Date(e.bookingDate).getTime(), e.balance]) },
		];

		// add the yAxis formatting
		this.lineChart.yAxis.labels = {
			formatter: function () {
				return Format.money(this.value || 0, currency);
			},
		};

		// modify the tooltip
		this.lineChart.tooltip = {
			pointFormatter: function () {
				return "<span style='color:" + this.color + "'>\u25CF</span>&nbsp;" + this.series.name + ": <b>" + Format.money(this.y, currency) + "</b><br/>";
			},
		};

		//console.log("chart", this.chart);
		this.setState({
			data: transactions,
			columns,
			footer,
			topLineChart: this.lineChart,
			sort: this.sort,
		});
	};

	// @Overwrite
	handleData = (data) => {
		const { dropDownFilterValue } = this.state;
		const { param } = this.props;
		const transactions = data[1];

		// Build Up Selection Dropdown
		const stockings = {};
		transactions.forEach((t) => {
			stockings[t.stockingId] = t.stockingName;
		});
		const dropDownFilter = [
			{ value: " ", label: "Alle" },
			...Object.keys(stockings)
				.map((s) => ({ value: s, label: stockings[s] }))
				.sort((a, b) => a.label > b.label),
		];

		const ddfValue =
			dropDownFilterValue && dropDownFilter.map((dd) => dd.value).indexOf(dropDownFilterValue) >= 0 ? dropDownFilterValue : param != null ? param : " ";
		this.setState({ originalData: data, dropDownFilter, dropDownFilterValue: ddfValue }, this.refreshData);
	};
}

export default TransactionList;
