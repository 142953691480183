import React, { PureComponent } from "react";
import PlateHeader from "./plateHeader";
import PlateCenterTwoLine from "./plateCenterTwoLine";
import PlateCenterOneLine from "./plateCenterOneLine";
import PlateFooter from "./plateFooter";
import { isFuture } from "../../../services/dateService";

class MonthPlate extends PureComponent {
	state = {};

	isCurrentMonth = () => {
		const { month } = this.props;
		const d = new Date();
		return d.getFullYear() === month.getFullYear() && d.getMonth() === month.getMonth();
	};

	isFuture = () => {
		return isFuture(this.props.month);
	};

	getStyle = () => {
		let color = "";
		if (this.isCurrentMonth()) color = "#FFF8DC";
		return { fontSize: "15px", backgroundColor: color };
	};

	getClasses = (isFuture) => {
		const base = "stockingPlate border-start border-secondary float-start";
		return base + (!isFuture || this.isCurrentMonth() ? "" : " bg-light");
	};

	handleCreate = (transactionType) => {
		const { month, onCreate } = this.props;
		onCreate(transactionType, month);
	};

	processTransactions = (transactions) => {
		let deposits = 0;
		let payouts = 0;
		let depositCount = 0;
		let payoutCount = 0;
		let unprocessedDepositCount = 0;
		let unprocessedPayoutCount = 0;
		transactions.forEach((trx) => {
			if (trx.type === "deposit") {
				depositCount++;
				if (!trx.processed) unprocessedDepositCount++;
				deposits += trx.value;
			} else if (trx.type === "payout") {
				payoutCount++;
				if (!trx.processed) unprocessedPayoutCount++;
				payouts += -1 * trx.value;
			}
		});
		return { depositCount, payoutCount, deposits, payouts, unprocessedDepositCount, unprocessedPayoutCount };
	};

	render() {
		const {
			display,
			balance,
			currency,
			transactions,
			onTransactionProcessed,
			onTransactionNotProcessed,
			onTransactionMoveForward,
			onTransactionMoveBackward,
			onTransactionDelete,
			onTransactionChangeDescription,
			onTransactionChangeValue,
		} = this.props;
		const isFuture = this.isFuture();

		const { depositCount, payoutCount, deposits, payouts, unprocessedDepositCount, unprocessedPayoutCount } = this.processTransactions(transactions);

		return (
			<div className={this.getClasses(isFuture)} style={this.getStyle()}>
				<PlateHeader
					display={display}
					currency={currency}
					transactions={transactions}
					isCurrentMonth={this.isCurrentMonth()}
					isFuture={isFuture}
					deposits={unprocessedDepositCount || depositCount}
					payouts={unprocessedPayoutCount || payoutCount}
					unprocessedDeposits={unprocessedDepositCount > 0}
					unprocessedPayouts={unprocessedPayoutCount > 0}
					onTransactionProcessed={onTransactionProcessed}
					onTransactionNotProcessed={onTransactionNotProcessed}
					onTransactionMoveForward={onTransactionMoveForward}
					onTransactionMoveBackward={onTransactionMoveBackward}
					onTransactionDelete={onTransactionDelete}
					onTransactionChangeDescription={onTransactionChangeDescription}
					onTransactionChangeValue={onTransactionChangeValue}
					onCreate={this.handleCreate}
				/>
				<div style={{ height: "48px" }}>
					{display === "both" && (
						<PlateCenterTwoLine isFuture={isFuture} currency={currency} deposits={deposits} payouts={payouts} onCreate={this.handleCreate} />
					)}
					{display === "deposits" && <PlateCenterOneLine isFuture={isFuture} currency={currency} value={deposits} />}
					{display === "payouts" && <PlateCenterOneLine isFuture={isFuture} currency={currency} value={payouts} />}
				</div>
				<PlateFooter currency={currency} balance={balance} />
			</div>
		);
	}
}

export default MonthPlate;
