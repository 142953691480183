import React from "react";

const AccordionItem = (props) => {
	const { id, show, title, parentId, render } = props;

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id={"heading" + id}>
				<button
					className={"accordion-button" + (show ? "" : " collapsed")}
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={"#collapse" + id}
					aria-expanded={show}
					aria-controls={"collapse" + id}
				>
					{title}
				</button>
			</h2>
			<div
				id={"collapse" + id}
				className={"accordion-collapse collapse" + (show ? " show" : "")}
				aria-labelledby={"heading" + id}
				data-bs-parent={"#" + parentId}
			>
				<div className="accordion-body">{render && render()}</div>
			</div>
		</div>
	);
};

export default AccordionItem;
