import React, { Component } from "react";
import Format from "../../../services/formattingService";

class PlateCenterOneLine extends Component {
	getSpanClasses = () => {
		const { isFuture } = this.props;
		return isFuture ? "text-muted lead" : "text-dark lead";
	};

	render() {
		const { currency, value } = this.props;
		return (
			<React.Fragment>
				<div className="d-flex justify-content-center align-items-center pt-2 mb-0 px-1">
					<span className={this.getSpanClasses()}>{value !== 0 && Format.money(value, currency)}</span>
				</div>
			</React.Fragment>
		);
	}
}

export default PlateCenterOneLine;
