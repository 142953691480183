import React, { Component } from "react";
import Input from "../common/forms/input";
import Radio from "../common/forms/radio";
import Validation from "../../services/validation";
import radioItems from "../../conf/radioItems.json";

class BalanceDistributionSubform extends Component {
	stockingTypeRadioItems = radioItems.stockingTypes;

	findStockingTypeLabel = (type) => {
		return this.stockingTypeRadioItems.filter((i) => i.id === type)[0].label;
	};

	handleChange = (e) => {
		const { name, value, type } = e.currentTarget;
		const errors = { ...this.state.errors };

		const convertedValue = type === "number" && value !== "" ? Number(value) : value;
		const errorMessage = Validation.validateProperty(name, convertedValue, Validation.schemas.balanceDistributionSubformSchema);
		if (errorMessage) errors[name] = errorMessage;
		else delete errors[name];

		this.setState({ errors });
		this.props.onChange(e);
	};

	handleSubmit = () => {
		this.props.onSubmitted();
	};

	validate = () => {
		return Validation.validate(this.props.data, Validation.schemas.balanceDistributionSubformSchema);
	};

	state = {
		errors: {},
	};

	render() {
		const { data } = this.props;
		const { errors } = this.state;

		return (
			<div className="row">
				<div className="col-lg">
					{data.editing && (
						<Input
							label="Sparstrumpf-Name"
							placeholder="z.B. Urlaub"
							name="name"
							error={errors.name}
							type="text"
							value={data.name}
							onChange={this.handleChange}
						/>
					)}

					{data.editing && (
						<Radio
							label="Sparstrumpf-Typ"
							name="type"
							isWithinModal={true}
							error={errors.type}
							value={data.type}
							items={this.stockingTypeRadioItems}
							onChange={this.handleChange}
						/>
					)}
					{data.editing && (
						<button type="submit" className="btn btn-primary" disabled={this.validate()} onClick={this.handleSubmit}>
							Hinzufügen
						</button>
					)}

					{!data.editing && (
						<div className="mb-3">
							<div>Sparstrumpf</div>
							<div className="text-muted">
								{data.name} <span className="small">({this.findStockingTypeLabel(data.type)})</span>
							</div>
						</div>
					)}
				</div>
				<div className="col-lg">
					{!data.editing && (
						<Input
							label="Betrag"
							placeholder=""
							name="balance"
							error={errors.balance}
							type="number"
							step="0.01"
							value={data.balance}
							onClick={(e) => e.currentTarget.select()}
							onChange={this.handleChange}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default BalanceDistributionSubform;
