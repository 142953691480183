import React, { Component } from "react";
import "../../css/Main.css";
import ApplicationContext from "../../services/applicationContext";
import reports from "../../services/reports";
import Format from "../../services/formattingService";
import Select from "../common/forms/select";
import DateRangeSelector from "./reports/dateRangeSelector";

class Reports extends Component {
	static contextType = ApplicationContext;

	state = {
		report: null,
		from: null,
		to: null,
		showDateRangeSelector: false,
	};

	handleReportSelect = (e) => {
		const { value } = e.currentTarget;
		const report = reports.find((r) => r.key === value);
		this.setState({ report, from: null, to: null });
	};

	handleRangeSelect = (from, to) => {
		this.setState({ from, to, showDateRangeSelector: false });
	};

	toggleShowDateRangeSelector = () => {
		this.setState({ showDateRangeSelector: !this.state.showDateRangeSelector });
	};

	fetchReport = () => {
		const { report: reportKey, param } = this.props.match.params;
		const report = reports.find((r) => r.key === reportKey);
		this.setState({ report, from: null, to: null, param });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params !== this.props.match.params) this.fetchReport();
	}

	componentDidMount() {
		this.fetchReport();
	}

	render() {
		const { report, from, to, showDateRangeSelector, param } = this.state;
		const { account } = this.props;
		const ReportComponent = report != null ? report.component : null;
		const reportsSelect = [{ value: "none", label: "Bericht wählen..." }, ...reports.map((r) => ({ value: r.key, label: r.title }))];

		return (
			<React.Fragment>
				{/* Row #1 Headline and Date Range */}
				<div className="row mx-3">
					<div className="col-md-1 col-lg-2 col-xxl-3"></div>
					<div className="col-md-10 col-lg-8 col-xxl-6 mb-2">
						<center>
							<h1 className="mt-5">Berichte</h1>
							<div className="mt-4">
								{report && (
									<div>
										<div className="mt-3 fs-3 border-bottom border-dark">{report.title}</div>
										<div className="" onClick={this.toggleShowDateRangeSelector} style={{ cursor: "pointer" }}>
											&nbsp;{from && to && Format.date(from) + " - " + Format.date(to)}&nbsp;
										</div>
									</div>
								)}
								{!report && <Select name="report" options={reportsSelect} onChange={this.handleReportSelect} />}
							</div>
						</center>
					</div>
					<div className="col-auto"> </div>
				</div>

				{/* Row #2 Date Range Picker */}
				{report && (
					<div className="row mx-3">
						<div className="col-md-1 col-lg-2 col-xxl-3"></div>
						<div className="col-md-10 col-lg-8 col-xxl-6 mb-5">
							{(showDateRangeSelector || !from) && <DateRangeSelector onRangeSelect={this.handleRangeSelect} from={from} to={to} />}
						</div>
						<div className="col-auto"> </div>
					</div>
				)}

				{/* Row #3 The Report Component */}
				{ReportComponent && from && to && (
					<div className="row mx-1 mb-5">
						<div className="col-12">
							<ReportComponent account={account} reportName={report.reportName} from={from} to={to} param={param} />
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default Reports;
