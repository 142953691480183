import React, { Component } from "react";
import ContactForm from "./forms/contactForm";

class Contact extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row mx-3">
					<div className="col-md-2 col-lg-3 col-xl-3"></div>
					<div className="col-md-8 col-lg-6 col-xl-6 mb-5">
						<center>
							<h1 className="mt-5">Kontakt</h1>
							<p className="my-5 lead">
								Du hast Fragen, Anregungen oder Tipps? Dann schreib uns doch ein paar nette Zeilen. Wir bemühen uns, alle Kontakt-Anfragen
								innerhalb von 48 Stunden zu beantworten.
							</p>
						</center>
						<ContactForm history={this.props.history} />
					</div>
					<div className="col-auto"> </div>
				</div>
			</React.Fragment>
		);
	}
}

export default Contact;
