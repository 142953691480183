import React, { Component } from "react";
import Format from "../../../services/formattingService";

class PlateFooter extends Component {
	getBalanceColor = () => {
		if (this.props.balance >= 0) return "text-success";
		else return "text-danger";
	};

	render() {
		const { currency, balance } = this.props;
		return (
			<div className="text-center mx-2 fw-bold small border-top border-secondary" style={{ paddingTop: "2px", marginTop: "6px" }}>
				<span className={this.getBalanceColor()}>{Format.money(balance, currency)}</span>
			</div>
		);
	}
}

export default PlateFooter;
