import React, { Component } from "react";
import { PencilFill, CheckLg, XLg } from "react-bootstrap-icons";
import ApplicationContext from "../../../services/applicationContext";
import Format from "../../../services/formattingService";
import Validation from "../../../services/validation";
import errorMessages from "../../../conf/errors.json";

class EditableEntry extends Component {
	static contextType = ApplicationContext;
	editRecurringOrTransactionText =
		"Diese Buchung ist Bestandteil einer wiederkehrenden Buchungsregel. Möchtest Du die Einzelbuchung oder die Regel bearbeiten?";
	editRecurringOrTransactionButtons = [
		{ label: "Einzelbuchung", value: "transaction", color: "primary" },
		{ label: "Regel", value: "recurring", color: "warning" },
	];

	display = React.createRef();
	editor = React.createRef();

	getPencilClasses = (disabled, hasLicense) => {
		return "ms-1 mb-1 " + (disabled || !hasLicense ? "text-secondary opacity-50 " : "text-primary ") + (!hasLicense ? "license-expired" : "");
	};

	getPencilHint = (disabled, hasLicense) => {
		if (!hasLicense) return errorMessages["license_expired"];
		if (disabled) return errorMessages["transaction_already_processed"];
		return "Ändern";
	};

	getCheckClasses = (disabled) => {
		return "ms-1" + (disabled ? " opacity-50 text-secondary" : " text-primary");
	};

	getButtonStyles = (disabled) => {
		return !disabled ? { cursor: "pointer" } : null;
	};

	getClasses = (truncate) => {
		return "text-nowrap" + (truncate ? " text-truncate" : "");
	};

	getStyles = (maxWidth) => {
		if (maxWidth) return { maxWidth };
		else return null;
	};

	handlePencilClick = () => {
		const { editAcknowledgeDialog } = this.props;
		if (editAcknowledgeDialog) {
			this.context.showDialogModal("Achtung", this.editRecurringOrTransactionText, this.editRecurringOrTransactionButtons, "md", this.handleDialogResult);
		} else {
			this.enableEditing(false);
		}
	};

	enableEditing = (editRecurring) => {
		const { currency, factor, value, onColorChange } = this.props;

		if (editRecurring) onColorChange("warning");
		const editorValue = currency ? value * (factor || 1) : value;
		const width = this.display.current.clientWidth;
		this.setState({ editorValue, width, editing: true, editRecurring }, () => this.editor.current.select());
	};

	handleDialogResult = (value) => {
		this.context.hideModal();
		this.enableEditing(value === "recurring");
	};

	handleChange = (e) => {
		const { validationSchema, currency } = this.props;
		const { value } = e.currentTarget;
		const editorValue = currency ? Number(value) || value : value;
		const errorMessage = Validation.validateProperty("editorValue", editorValue, validationSchema);
		this.setState({ editorValue, errorMessage });
	};

	handleCheckClick = () => {
		const { currency, onChange, factor, onColorChange } = this.props;
		const { editorValue, editRecurring } = this.state;
		if (this.validate() === null) {
			const value = currency ? editorValue * (factor || 1) : editorValue;
			onChange(value, editRecurring);
			onColorChange(null);
			this.setState({ editing: false });
		}
	};

	handleCancelClick = () => {
		const { onColorChange } = this.props;
		onColorChange(null);
		this.setState({ editing: false, errorMessage: null });
	};

	handleKeyDown = (e) => {
		const code = e.keyCode ? e.keyCode : e.which ? e.which : 0;
		if (code === 13) {
			this.handleCheckClick();
		} else if (code === 27) {
			this.handleCancelClick();
		}
	};

	validate = () => {
		return Validation.validateProperty("editorValue", this.state.editorValue, this.props.validationSchema);
	};

	state = {
		editing: false,
		editRecurring: false,
		editorValue: "",
		errorMessage: null,
	};

	render() {
		const { currency, factor, disabled, align, truncate, maxWidth, value } = this.props;
		const { editorValue, editing, width, errorMessage } = this.state;
		const hasLicense = this.context.getLicenseType();
		const invalid = this.validate();

		return (
			<React.Fragment>
				<div className={"d-flex text-nowrap align-items-baseline" + (align ? " justify-content-" + align : "")}>
					{!editing && (
						<div ref={this.display} className={this.getClasses(truncate)} style={this.getStyles(maxWidth)}>
							{currency ? Format.money(value * (factor || 1), currency) : value}
						</div>
					)}
					{editing && (
						<div>
							<input
								ref={this.editor}
								type={currency ? "number" : "text"}
								value={editorValue}
								className="editable-entry"
								step="0.01"
								onChange={this.handleChange}
								onKeyDown={this.handleKeyDown}
								style={{ width: (currency ? 80 : width) + "px" }}
							/>
						</div>
					)}

					{!editing && (
						<div title={this.getPencilHint(disabled, hasLicense)}>
							<PencilFill
								className={this.getPencilClasses(disabled, hasLicense)}
								size={12}
								style={this.getButtonStyles(disabled)}
								onClick={!disabled && hasLicense ? this.handlePencilClick : null}
							/>
						</div>
					)}
					{editing && (
						<div title="Speichern">
							<CheckLg
								className={this.getCheckClasses(invalid)}
								size={20}
								style={{ ...this.getButtonStyles(invalid), marginBottom: "2px", marginTop: "-3px" }}
								onClick={invalid ? undefined : this.handleCheckClick}
							/>
							<XLg
								className="text-primary"
								size={14}
								style={{ cursor: "pointer", marginBottom: "4px", marginLeft: "2px" }}
								onClick={this.handleCancelClick}
							/>
						</div>
					)}
				</div>
				{errorMessage && (
					<div className="mt-1 text-danger small" style={{ width: "110px" }}>
						{errorMessage}
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default EditableEntry;
