import React from "react";
import { Link } from "react-router-dom";
import { Gear, ListColumnsReverse, BoxArrowInRight, BoxArrowRight } from "react-bootstrap-icons";

const StockingButtonGroup = (props) => {
	const { onSettingsClick, onCreateDepositClick, onCreatePayoutClick, stocking } = props;
	return (
		<React.Fragment>
			<button className="btn btn-sm btn-secondary" style={{ paddingLeft: "5px", paddingRight: "1px" }} onClick={onSettingsClick} title="Einstellungen">
				<Gear className="me-1" size={20} />
			</button>
			<Link to={"/app/berichte/buchungsuebersicht/" + stocking.stockingId}>
				<button className="btn btn-sm btn-secondary" style={{ paddingLeft: "5px", paddingRight: "1px" }} title="Buchungen">
					<ListColumnsReverse className="me-1" size={20} />
				</button>
			</Link>
			<button
				className="btn btn-sm btn-secondary"
				style={{ paddingLeft: "5px", paddingRight: "1px" }}
				onClick={onCreateDepositClick}
				title="Neue Einzahlung"
			>
				<BoxArrowInRight className="me-1" size={20} />
			</button>
			<button
				className="btn btn-sm btn-secondary"
				style={{ paddingLeft: "5px", paddingRight: "1px" }}
				onClick={onCreatePayoutClick}
				title="Neue Auszahlung"
			>
				<BoxArrowRight className="me-1" size={20} />
			</button>
		</React.Fragment>
	);
};

export default StockingButtonGroup;
