import React from "react";
import { Eyedropper } from "react-bootstrap-icons";
import "../../../css/colorPicker.css";

const ColorPicker = (props) => {
	const { reference, name, label, notice, error, presets, value, onChange, ...rest } = props;
	const manualColor = !presets.includes(value);

	const handlePresetSelect = (color) => {
		const e = {
			currentTarget: {
				type: "color",
				name,
				value: color,
			},
		};
		onChange(e);
	};

	return (
		<div className="mb-3">
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<div className="d-flex flex-wrap" style={{ maxWidth: "170px" }}>
				{presets.map((p) => (
					<div
						key={p}
						className={"colorPickerPreset" + (value === p ? " selected" : "")}
						style={{ backgroundColor: p }}
						onClick={() => handlePresetSelect(p)}
					></div>
				))}
			</div>
			<div className="d-flex flex-nowrap">
				<span style={{ marginTop: "2px", marginLeft: "6px", marginRight: "15px" }}>Wählen: </span>
				<input
					{...rest}
					ref={reference}
					name={name}
					id={name}
					type="color"
					value={manualColor ? value : "#ffffff"}
					className={"form-control form-control-color" + (manualColor ? " selected" : "")}
					onChange={onChange}
				/>
				<Eyedropper size="20" style={{ marginLeft: "-5px", marginTop: "3px" }} />
			</div>

			{error && <div className="form-text text-danger ms-1">{error}</div>}
			{!error && <div className="form-text ms-1">{notice}</div>}
		</div>
	);
};

export default ColorPicker;
