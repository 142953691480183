import { Component } from "react";
import Validation from "../../../services/validation";

class Form extends Component {
	state = {
		data: {}, // should be overwritten
		errors: {},
	};

	getSchema = () => {}; // should be overwritten
	getPropertySchema = () => null; // may be used if field validation needs different schemas

	validate = (schema = this.getSchema) => {
		return Validation.validate(this.state.data, schema());
	};

	validateProperty = (name, value) => {
		return Validation.validateProperty(name, value, this.getPropertySchema() || this.getSchema());
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });

		if (errors && Object.keys(errors).length > 0) {
			return;
		}

		this.doSubmit();
	};

	handleChange = (e) => {
		const { name, value, checked, type } = e.currentTarget;
		const data = { ...this.state.data };
		const errors = { ...this.state.errors };

		//console.log(name, value, checked, type);

		if (type === "checkbox") data[name] = checked;
		else if (type === "number") data[name] = value === "" ? "" : Number(value);
		else if (type === "date") data[name] = value !== "" ? new Date(value) : null;
		else data[name] = value;

		const errorMessage = this.validateProperty(name, data[name]);
		if (errorMessage) errors[name] = errorMessage;
		else delete errors[name];

		this.setState({ data, errors });
	};
}

export default Form;
